
























import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

import QsButton from 'qs_vuetify/src/components/QsButton.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import TagsMixin from '@/mixins/TagsMixin';

import { PersistedCampaign } from 'qs_vuetify/src/types/models';
import axios from 'qs_vuetify/src/plugins/axios';
import CallCampaignsAutocomplete from '../CallCampaign/CallCampaignsAutocomplete.vue';

const auth: any = namespace('auth');
const contactsViews: any = namespace('contactsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFilters,
    CallCampaignsAutocomplete,
    QsRelationField,
  },
})
export default class ContactExchangeModal extends mixins(DataRouteGuards, TagsMixin) {
  @auth.Getter instanceId!: number;
  @contactsViews.Getter contactExchangeModal!: boolean;
  @contactsViews.Mutation setContactExchangeModal: any;

  @Prop({ required: true, type: [Number, String] }) contactId!: number | string;
  @Prop({ required: false, type: Object, default: {} }) viewParams?: any;
  @Prop({ required: false, type: Array, default: () => [] }) excludedCampaigns!: number[];

  selectedCallCampaign: number | null = null;
  queryDefinition: any = {}

  created() {
    this.fetchCallCampaigns();
  }

  @Watch('contactExchangeModal') @Watch('instanceId')
  fetchCallCampaigns() {
    if (this.contactExchangeModal && this.instanceId) {
      this.$store.dispatch('call_campaigns/index', { prefix: `/instances/${this.instanceId}` });
    }
  }

  closeModal(): void {
    this.setContactExchangeModal(false);
  }

  async submitForm(): Promise<void> {
    if (this.selectedCallCampaign) {
      await axios.put(`/call_campaigns/${this.selectedCallCampaign}/contacts/${this.contactId}`);
      const { data } = await axios.get(`/call_campaigns/${this.selectedCallCampaign}/contact_exchanges/next?contact_id=${this.contactId}`);

      if (data) {
        this.$router.push({
          name: 'CallCampaign',
          params: {
            call_campaign_id: this.selectedCallCampaign.toString(),
            id: data.id,
          },
        });
      }

      const campaignName = this.$store.state.call_campaigns.data.find((cp: PersistedCampaign) => cp.id === this.selectedCallCampaign)?.name ?? '';

      this.$store.commit('global/addNotification', {
        color: 'success',
        message: `Le contact a été ajouté à la campagne d'appel "${campaignName}".`,
      });
    }

    this.closeModal();
    this.$emit('click:close');
  }
}
