









































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import {
  PersistedCallCampaign as CallCampaign,
  PersistedContact as Contact,
  User,
} from 'qs_vuetify/src/types/models';

import axios from 'qs_vuetify/src/plugins/axios';

import ContactListItem from '@/components/ContactListItem.vue';

const global: any = namespace('global');
const store: any = namespace('call_campaigns');

@Component({
  components: {
    ContactListItem,
    QsActionModal,
    QsButton,
    QsRelationField,
  },
})
export default class CallCampaignUsersModal extends Vue {
  @global.Mutation addNotification!: Function;
  @store.Getter item!: CallCampaign;

  @Prop({ required: true, type: [Number, String] }) id!: number | string;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  contact: Contact | null = null;
  isLoading: boolean = false;
  user: User | null = null;

  queryDefinition: any = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      '!status': [
        'DBL',
        'DEC',
        'RET',
      ].join(','),
      fields: [
        'district',
        'email',
        'full_name',
        'status',
        'searchable_text',
        'user.*',
        'v1_contact_id',
      ].join(','),
    },
  }

  @Watch('contact')
  onContactChanged(contact: Contact): void {
    if (contact?.user?.id) {
      this.user = contact.user;
    } else if (contact?.email) {
      this.createUser(contact);
    } else {
      this.user = null;
    }
  }

  async createUser(contact: Contact) {
    const { data: user } = await axios.post('users', {
      contact_id: contact.id,
      email: contact.email,
    }, {
      params: {
        fields: '*',
      },
    });

    this.user = user;
  }

  async putCallCampaignUser(): Promise<void> {
    this.isLoading = true;
    if (this.contact && this.user && this.user.id) {
      try {
        await axios.put(`/call_campaigns/${this.id}/users/${this.user.id}`, {}, {
          params: {
            fields: 'description,instance.name,name',
          },
        });

        this.addNotification({
          color: 'success',
          message: `${this.contact.full_name} a été ajouté·e aux militant·es.`,
        });

        this.syncedValue = false;

        this.$emit('added');
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: `Erreur lors de l'ajout de ${this.contact.full_name} aux militant·es.`,
        });
      }
    }
    this.isLoading = false;
  }
}
