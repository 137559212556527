import Vue from 'vue';

import dayjs from 'qs_vuetify/src/plugins/dayjs';
import filters from 'qs_vuetify/src/plugins/filters';
import helpers from 'qs_vuetify/src/plugins/helpers';
import i18n from '@/plugins/i18n';
import 'qs_vuetify/src/plugins/sentry';
import { RestParams } from 'qs_vuetify/src/types/states';
import 'qs_vuetify/src/plugins/vMask';
import 'qs_vuetify/src/plugins/vueCurrencyField';
import 'qs_vuetify/src/plugins/vueHead';
import vuetify from 'qs_vuetify/src/plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store';


import './styles/main.scss';

Vue.config.productionTip = true;

declare module 'vue/types/vue' {
  interface Vue {
    viewParams?: { [slug: string]: RestParams };
    $dayjs: any;
    $filters: any;
    $helpers: any;
  }
}

Object.keys(filters).forEach((f) => Vue.filter(f, filters[f]));

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$filters = filters;
Vue.prototype.$helpers = helpers;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
