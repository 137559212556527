




















































import Component from 'vue-class-component';

import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';

import BaseItemCard from '@/components/BaseItemCard.vue';
import CallCampaignProgress from '@/components/CallCampaign/CallCampaignProgress.vue';

@Component({
  components: {
    CallCampaignProgress,
  },
})
export default class CallCampaignCard extends BaseItemCard<PersistedCallCampaign> {}
