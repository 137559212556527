





















import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import QsLayoutNavigation from 'qs_vuetify/src/components/Layout/QsLayoutNavigation.vue';
import QsLayoutNotifications from 'qs_vuetify/src/components/Layout/QsLayoutNotifications.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import { MenuItem } from 'qs_vuetify/src/types/components';
import { Instance, User } from 'qs_vuetify/src/types/models';

import { routes } from '@/router/index';

@Component({
  components: {
    QsLayoutNavigation,
    QsLayoutNotifications,
  },
})
export default class App extends mixins(AuthenticationMixin) {
  @Getter('instanceId', { namespace: 'auth' }) instanceId!: number | null;
  @Action('loadUser', { namespace: 'auth' }) loadUser!: any;
  @Getter('token', { namespace: 'auth' }) token!: string | null;
  @Getter('user', { namespace: 'auth' }) user!: User | null;
  @Mutation('instanceId', { namespace: 'auth' }) setInstanceId!: any;

  get primaryMenuItems(): Array<MenuItem> {
    return routes
      .filter((r) => r.meta && r.meta.menus)
      .map((r) => ({
        ...r.meta,
        route: r.name,
      }))
      .filter((i) => i.menus?.indexOf('primary') !== -1)
      .filter((i) => this.userHasAll(i.requires || []));
  }

  get adminMenuItems(): Array<MenuItem> {
    if (!this.userIsConnected) {
      return [];
    }

    return [
      {
        title: 'Aide',
        route: process.env.VUE_APP_LACENTRALE_RUBRIQUES_URL,
        target: '_blank',
        icon: 'mdi-help',
        color: 'primary lighten-3',
        requires: [],
      },
      {
        title: 'Rapport de bogue',
        route: process.env.VUE_APP_BUG_REPORT_URL,
        target: '_blank',
        icon: 'mdi-help',
        color: 'primary lighten-3',
        requires: [],
      },
      {
        title: 'Profil',
        route: process.env.VUE_APP_PROFIL_URL,
        target: '_blank',
        icon: 'mdi-user',
        color: 'primary lighten-3',
        requires: [],
      },
    ];
  }

  async mounted() {
    await this.loadUserAndSetInitialInstance(); // eslint-disable-line class-methods-use-this
  }

  async loadUserAndSetInitialInstance() {
    const instanceFields = [
      'id',
      'name',
      'rights_slugs',
      'active_volunteers.contact_id',
    ];
    if (!this.user) {
      if (this.token) {
        await this.loadUser([
          'id', 'email', 'superadmin', 'contact_id', 'contact_name',
          'favorites.id',
          ...instanceFields.map((f) => `instances.${f}`),
          ...instanceFields.map((f) => `inherited_instances.${f}`),
        ].join(','));
      } else {
        if (this.$route.fullPath !== '/') {
          this.$router.push('/');
        }
        this.$store.commit('global/loginDialog', true);
      }
    }

    if (this.user) {
      if (!this.instanceId && this.user?.instances && this.user.instances.length > 0) {
        this.setInstanceId(this.user.instances[0].id);
      }
    }
  }

  get lockInstance(): boolean {
    return !!this.$route?.meta?.lockInstance;
  }

  get userInstances(): Array<Instance> {
    return this.user?.inherited_instances || [];
  }

  redirectToHome() {
    this.$router.push('/');
  }

  @Watch('instanceId')
  onInstanceIdChanged(instanceId: number | null) {
    if (instanceId) {
      this.$store.commit('tags/prefix', `/instances/${instanceId}`);

      this.$store.dispatch('globalViews/tags', {
        accessible_from: instanceId,
        fields: 'description,instance.name,name',
        per_page: -1,
      });
    } else {
      this.$store.commit('call_campaigns/prefix', '');
      this.$store.commit('tags/prefix', '');
    }
  }
}
