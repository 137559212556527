
































import Component, { mixins } from 'vue-class-component';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import { Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    QsActionModal,
    QsFormBuilder,
  },
})
export default class ModelDialog extends mixins(
  DataRouteGuards,
  FormMixin,
  I18nMixin,
) {
  @Prop({ type: String, required: true }) dataStoreName!: string;
  @Prop({ type: String, required: true }) viewStoreName!: string;
  @Prop({ type: [String, Number], required: true }) id!: string | number;

  get error(): ErrorResponse {
    return this.$store.getters[`${this.dataStoreName}/error`];
  }

  get form(): Form {
    return this.$store.getters[`${this.dataStoreName}/form`];
  }

  get item(): any {
    return this.$store.getters[`${this.dataStoreName}/item`];
  }

  get loading(): boolean {
    return this.$store.getters[`${this.dataStoreName}/loading`];
  }

  get modal() {
    return !!this.$route.params.id;
  }

  set modal(value: boolean) {
    if (value === false) {
      const nestedPathSegmentsCount = this.$router.currentRoute.matched.length;

      if (nestedPathSegmentsCount >= 2) {
        // go from /model/:id to /model
        this.$router.replace(
          this.$router.currentRoute.matched[nestedPathSegmentsCount - 2],
        );
        (this.$parent as DataRouteGuards).reloadDataRoutesData();
      }
    }
  }

  get modelName() {
    return this.dataStoreName;
  }

  get slug() {
    return this.dataStoreName;
  }

  async submitForm() {
    await this.submit();
    this.modal = false;
  }

  syncItem(value: any) {
    this.$store.commit(`${this.dataStoreName}/item`, value);
  }
}
