var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.callCampaign
    ? _c(
        "v-container",
        { staticClass: "call-campaign-exchange", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "flex-shrink-1 flex-grow-0" },
            [
              _c("v-col", { attrs: { cols: "3" } }, [
                _c("h1", [_vm._v(_vm._s(_vm.callCampaign.name))])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _vm.stats
                    ? _c("call-campaign-progress", {
                        attrs: { data: _vm.stats },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "chips",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "district-chip" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.callCampaign.instance.district
                                            .name
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1086981368
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "flex-grow-1",
              staticStyle: { position: "relative" }
            },
            [
              _c(
                "div",
                { staticClass: "absolute-fill d-flex" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _vm.itemReady && !_vm.loading
                        ? _c(
                            "v-card",
                            {
                              staticClass:
                                "qs-blue--text full-height-card contact-details",
                              attrs: { tile: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-uppercase text-h2" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      directives: [
                                        {
                                          name: "set-previous-location",
                                          rawName: "v-set-previous-location"
                                        }
                                      ],
                                      staticClass: "contact-details__name",
                                      attrs: {
                                        to: {
                                          name: "ContactForm",
                                          params: { id: _vm.contact.id }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.contact.first_name) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-bold ml-2"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.contact.last_name)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c("contact-favorite-button", {
                                    attrs: { id: _vm.contact.id }
                                  })
                                ],
                                1
                              ),
                              _c("v-card-subtitle", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-h3 d-flex contact-details__identity"
                                  },
                                  [
                                    _c("editable-text-field", {
                                      attrs: {
                                        name: "gender",
                                        placeholder: "Genre manquant",
                                        "select-items": [
                                          { text: "Femme", value: "female" },
                                          { text: "Homme", value: "male" },
                                          {
                                            text: "Non-binaire / aucun genre",
                                            value: "other"
                                          }
                                        ],
                                        type: "enum"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.patchContact("gender")
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          _vm.contact.gender
                                            ? {
                                                key: "display",
                                                fn: function(ref) {
                                                  var value = ref.value
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("gender")(
                                                            value
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            : null
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.contact.gender,
                                        callback: function($$v) {
                                          _vm.$set(_vm.contact, "gender", $$v)
                                        },
                                        expression: "contact.gender"
                                      }
                                    }),
                                    _vm._v(", "),
                                    _c("editable-text-field", {
                                      attrs: {
                                        name: "birthdate",
                                        placeholder:
                                          "Date de naissance manquante",
                                        type: "date"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.patchContact("birthdate")
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          _vm.contact.birthdate
                                            ? {
                                                key: "display",
                                                fn: function(ref) {
                                                  var value = ref.value
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("age")(value)
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            : null
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.contact.birthdate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "birthdate",
                                            $$v
                                          )
                                        },
                                        expression: "contact.birthdate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass: "body-1 d-flex",
                                    staticStyle: { "white-space": "pre" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.contact.district.name) + " | "
                                    ),
                                    _c("editable-text-field", {
                                      attrs: {
                                        name: "language",
                                        placeholder:
                                          "Pas de préférence de langue",
                                        "select-items": [
                                          { text: "Anglais", value: "en" },
                                          { text: "Français", value: "fr" }
                                        ],
                                        type: "enum"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.patchContact(
                                            "main_language"
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.contact.main_language,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "main_language",
                                            $$v
                                          )
                                        },
                                        expression: "contact.main_language"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "contact-phone-details" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  text: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    _vm.isContactUnsubPhone = !_vm.isContactUnsubPhone
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: _vm.isContactUnsubPhone
                                                                    ? "qs-light-blue"
                                                                    : "qs-dark-blue"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.isContactUnsubPhone
                                                                        ? "$qs-phone-off"
                                                                        : "$qs-phone"
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1776874992
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.isContactUnsubPhone
                                                      ? _vm.$t(
                                                          "call_campaigns.sub_phone"
                                                        )
                                                      : _vm.$t(
                                                          "call_campaigns.unsub_phone"
                                                        )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            {
                                              class: _vm.isContactUnsubPhone
                                                ? "qs-light-blue--text"
                                                : "qs-dark-blue--text"
                                            },
                                            [
                                              _c("editable-text-field", {
                                                class: _vm.isContactUnsubPhone
                                                  ? "qs-light-blue--text"
                                                  : "qs-dark-blue--text",
                                                attrs: {
                                                  mask: "### ###-####",
                                                  name: "home_phone",
                                                  placeholder: "-"
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.patchContact(
                                                      "home_phone"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.contact.home_phone,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.contact,
                                                      "home_phone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contact.home_phone"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.contact.email
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      text: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.isContactUnsubEmail = !_vm.isContactUnsubEmail
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color: _vm.isContactUnsubEmail
                                                                        ? "qs-light-blue"
                                                                        : "qs-dark-blue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.isContactUnsubEmail
                                                                            ? "$qs-email-off"
                                                                            : "$qs-email"
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3088069488
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.isContactUnsubEmail
                                                          ? _vm.$t(
                                                              "call_campaigns.sub_email"
                                                            )
                                                          : _vm.$t(
                                                              "call_campaigns.unsub_email"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                {
                                                  class: _vm.isContactUnsubEmail
                                                    ? "qs-light-blue--text"
                                                    : "qs-dark-blue--text"
                                                },
                                                [
                                                  _c("v-hover", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var hover =
                                                              ref.hover
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.setEmailsModal(
                                                                        true
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "min-width":
                                                                          "100px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .contact
                                                                              .email
                                                                              ? _vm
                                                                                  .contact
                                                                                  .email
                                                                              : "-"
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value: hover,
                                                                          expression:
                                                                            "hover"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "ml-2",
                                                                      attrs: {
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2571851075
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "contact-info-tabs-container"
                                    },
                                    [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "", height: "33px" } },
                                        [
                                          _c(
                                            "v-tabs",
                                            {
                                              staticClass:
                                                "rounded-lg contact-info-tabs",
                                              attrs: { "fixed-tabs": "" },
                                              model: {
                                                value: _vm.tabs,
                                                callback: function($$v) {
                                                  _vm.tabs = $$v
                                                },
                                                expression: "tabs"
                                              }
                                            },
                                            [
                                              _c("v-tabs-slider"),
                                              _c(
                                                "v-tab",
                                                {
                                                  staticClass:
                                                    "qs-orange--text",
                                                  attrs: { href: "#info" }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("$qs-membership")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tab",
                                                {
                                                  staticClass:
                                                    "qs-orange--text",
                                                  attrs: { href: "#tags" }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-tag-multiple")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          staticClass: "mt-8",
                                          model: {
                                            value: _vm.tabs,
                                            callback: function($$v) {
                                              _vm.tabs = $$v
                                            },
                                            expression: "tabs"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            {
                                              staticClass: "flex-grow-1",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { value: "info" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "absolute-fill d-flex flex-column"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "contact-info"
                                                    },
                                                    [
                                                      _c(
                                                        "contact-memberships",
                                                        {
                                                          attrs: {
                                                            item: _vm.contact
                                                          }
                                                        }
                                                      ),
                                                      _c(
                                                        "contact-contributions",
                                                        {
                                                          staticClass: "mt-12",
                                                          attrs: {
                                                            item: _vm.contact
                                                          }
                                                        }
                                                      ),
                                                      _c("contact-sources", {
                                                        staticClass: "mt-12",
                                                        attrs: {
                                                          item: _vm.contact
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-tab-item",
                                            {
                                              staticClass: "contact-tags",
                                              attrs: { value: "tags" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-1 mb-2",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "absolute-fill d-flex flex-column",
                                                      staticStyle: {
                                                        "overflow-y": "auto"
                                                      }
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.contact.tags,
                                                        function(tag) {
                                                          return [
                                                            _c("tag-chip", {
                                                              key: tag.id,
                                                              staticClass:
                                                                "flex-shrink-0",
                                                              attrs: {
                                                                name: tag.name
                                                              }
                                                            })
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              ),
                                              _c("tags-autocomplete", {
                                                staticClass: "add-tag-input",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "call_campaigns.add_tag"
                                                  )
                                                },
                                                on: {
                                                  change: _vm.addSelectedTag
                                                },
                                                model: {
                                                  value: _vm.selectedTagId,
                                                  callback: function($$v) {
                                                    _vm.selectedTagId = $$v
                                                  },
                                                  expression: "selectedTagId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { height: "100%" }
                            },
                            [_c("qs-loading-indicator")],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "call-campaign-exchange__exchange",
                      attrs: { cols: "12", md: "6" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          class:
                            "call-campaign-info " +
                            (_vm.loading ? "d-flex flex-column" : ""),
                          attrs: { tile: "", height: "100%" }
                        },
                        [
                          _vm.loading || !_vm.itemReady
                            ? _c("qs-loading-indicator")
                            : [
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "call-campaign-exchange__exchange__exchange"
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "text-uppercase text-h2 font-weight-bold"
                                          },
                                          [_vm._v(" Échange ")]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          {
                                            staticClass:
                                              "text-uppercase font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "call_campaigns.script"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.item.reserved_by_user &&
                                    _vm.item.reserved_by_user.id !== _vm.user.id
                                      ? _c(
                                          "v-col",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "v-alert",
                                              { attrs: { color: "warning" } },
                                              [
                                                _vm._v(
                                                  " Réservé par " +
                                                    _vm._s(
                                                      _vm.item.reserved_by_user
                                                        .contact_name
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " le " +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        _vm.item.reserved_at,
                                                        "D MMMM YYYY à HH:mm:ss"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass:
                                      "call-campaign-content flex-shrink-1 flex-grow-0"
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "call-campaign-script qs-blue white--text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.callCampaign.script
                                        )
                                      }
                                    }),
                                    _c("qs-form-builder", {
                                      attrs: {
                                        item: _vm.item.answers,
                                        form: _vm.callCampaign.form_definition,
                                        "model-name": "contact_exchange.answers"
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.item.answers = $event
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "enum",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              return [
                                                _c(
                                                  "qs-themed-select-field",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        description: descriptionOrNothing(
                                                          field.name
                                                        ),
                                                        items: _vm.labelsOrValues(
                                                          field.name,
                                                          field.def.rules.oneOf
                                                        ),
                                                        label:
                                                          field.def.label ||
                                                          labelOrName(
                                                            field.name
                                                          ),
                                                        name: field.name,
                                                        value: item[field.name]
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return update(
                                                            field.name,
                                                            $event
                                                              ? $event.value
                                                              : null
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "qs-themed-select-field",
                                                    field.def,
                                                    false
                                                  )
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "checkbox",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              var errorMessagesFor =
                                                ref.errorMessagesFor
                                              return [
                                                field.def.toggler
                                                  ? _c(
                                                      "qs-toggle-field",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            "is-toggler": true,
                                                            default:
                                                              item[
                                                                field.name
                                                              ] ||
                                                              field.def.default,
                                                            label:
                                                              field.def.label ||
                                                              labelOrName(
                                                                field.name
                                                              ),
                                                            description: descriptionOrNothing(
                                                              field.name
                                                            ),
                                                            value:
                                                              item[field.name],
                                                            name: field.name,
                                                            errors: errorMessagesFor(
                                                              field.name
                                                            )
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return update(
                                                                field.name,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "qs-toggle-field",
                                                        field.def,
                                                        false
                                                      )
                                                    )
                                                  : _c(
                                                      "qs-checkbox",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            "is-toggler":
                                                              field.def.toggler,
                                                            default:
                                                              item[
                                                                field.name
                                                              ] ||
                                                              field.def.default,
                                                            label:
                                                              field.def.label ||
                                                              labelOrName(
                                                                field.name
                                                              ),
                                                            description: descriptionOrNothing(
                                                              field.name
                                                            ),
                                                            value:
                                                              item[field.name],
                                                            name: field.name,
                                                            errors: errorMessagesFor(
                                                              field.name
                                                            )
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return update(
                                                                field.name,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "qs-checkbox",
                                                        field.def,
                                                        false
                                                      )
                                                    )
                                              ]
                                            }
                                          },
                                          {
                                            key: "textarea",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              return [
                                                _c(
                                                  "qs-themed-textarea-field",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label:
                                                          field.def.label ||
                                                          labelOrName(
                                                            field.name
                                                          ),
                                                        description: descriptionOrNothing(
                                                          field.name
                                                        ),
                                                        value: item[field.name],
                                                        name: field.name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return update(
                                                            field.name,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "qs-themed-textarea-field",
                                                    field.def,
                                                    false
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3131049154
                                      )
                                    }),
                                    _c("qs-toggle-field", {
                                      attrs: {
                                        label: _vm.$t(
                                          "call_campaigns.answered"
                                        ),
                                        name: "did_not_answer",
                                        default: _vm.answered,
                                        "is-toggler": true
                                      },
                                      model: {
                                        value: _vm.answered,
                                        callback: function($$v) {
                                          _vm.answered = $$v
                                        },
                                        expression: "answered"
                                      }
                                    }),
                                    _c("qs-toggle-field", {
                                      attrs: {
                                        disabled: _vm.answered,
                                        default: _vm.item.left_message,
                                        name: "left_message",
                                        label: _vm.$t(
                                          "call_campaigns.left_message"
                                        ),
                                        "is-toggler": true
                                      },
                                      model: {
                                        value: _vm.item.left_message,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "left_message",
                                            $$v
                                          )
                                        },
                                        expression: "item.left_message"
                                      }
                                    }),
                                    _c("qs-themed-textarea-field", {
                                      attrs: {
                                        name: "comments",
                                        label: "Commentaires"
                                      },
                                      model: {
                                        value: _vm.item.comments,
                                        callback: function($$v) {
                                          _vm.$set(_vm.item, "comments", $$v)
                                        },
                                        expression: "item.comments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                          _c(
                            "v-card-actions",
                            { staticClass: "d-flex call-campaign-actions" },
                            [
                              _c(
                                "qs-btn",
                                {
                                  staticClass: "white--text px-0",
                                  attrs: {
                                    disabled: _vm.loading,
                                    title: "Retirer le contact",
                                    color: "qs-light-blue",
                                    height: "100%"
                                  },
                                  on: { click: _vm.removeContact }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" mdi-account-remove ")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "qs-btn",
                                {
                                  staticClass: "flex-grow-1 white--text",
                                  attrs: {
                                    disabled: _vm.loading,
                                    title: "Quitter",
                                    color: "qs-orange",
                                    height: "100%",
                                    icon: "$qs-phone-down"
                                  },
                                  on: { click: _vm.quit }
                                },
                                [_vm._v(" Quitter ")]
                              ),
                              _c(
                                "qs-btn",
                                {
                                  staticClass: "flex-grow-1 qs-dark-blue--text",
                                  attrs: {
                                    title: "Continuer",
                                    disabled: _vm.loading,
                                    color: "qs-green",
                                    icon: "$qs-phone-next"
                                  },
                                  on: { click: _vm.completeExchange }
                                },
                                [_vm._v(" Continuer ")]
                              ),
                              _c(
                                "qs-btn",
                                {
                                  staticClass: "white--text px-0",
                                  attrs: {
                                    title: "Sauter",
                                    disabled: _vm.loading,
                                    color: "qs-light-blue",
                                    height: "100%"
                                  },
                                  on: { click: _vm.skipAndNext }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" mdi-skip-next ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("contact-history", {
                        staticClass: "full-height-card",
                        attrs: {
                          "model-name": "contacts",
                          item: _vm.contact,
                          loaded: !!_vm.contact
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "qs-confirm-dialog",
            {
              attrs: {
                prompt: "Campagne terminée",
                cancelText: "Quitter",
                confirmText: "Vider la liste et continuer",
                onCancel: function() {
                  _vm.endDialog = false
                  _vm.$router.replace("/")
                },
                onConfirm: _vm.clearSeenIds
              },
              model: {
                value: _vm.endDialog,
                callback: function($$v) {
                  _vm.endDialog = $$v
                },
                expression: "endDialog"
              }
            },
            [
              _vm._v(
                " Il ne reste plus d'appels à faire dans cette campagne, mais il reste des appels échoués. Désirez-vous réinitialiser votre session d'appels et réessayer les appels échoués? "
              )
            ]
          ),
          _vm.contact && _vm.contact.emails
            ? _c("contact-emails-modal", {
                attrs: {
                  items: _vm.contact.emails,
                  "contact-id": _vm.contact.id
                },
                on: { input: _vm.reloadDataRoutesData },
                model: {
                  value: _vm.showEmailsModal,
                  callback: function($$v) {
                    _vm.showEmailsModal = $$v
                  },
                  expression: "showEmailsModal"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }