import { Model } from 'qs_vuetify/src/types/models';
import { RestParams, RootState } from 'qs_vuetify/src/types/states';
import { Module } from 'vuex';

export type ViewsState<T extends Model> = {
  selectedItems: Array<T>;
  showExtraInformation: boolean;
  params: RestParams;
};

export default function buildViewModule<T extends Model>() {
  const views: Module<ViewsState<T>, RootState> = {
    namespaced: true,
    state: {
      params: {
        page: 1,
      },
      selectedItems: [],
      showExtraInformation: true,
    },
    getters: {
      params(state) {
        return state.params;
      },
      selectedItems(state) {
        return state.selectedItems;
      },
      showExtraInformation(state) {
        return state.showExtraInformation;
      },
    },
    mutations: {
      addSelectedItem(state, selectedItem) {
        if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
          state.selectedItems.push({
            ...selectedItem,
          });
        }
      },
      removeSelectedItem(state, selectedItem) {
        const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
        if (index !== -1) {
          state.selectedItems.splice(index, 1);
        }
      },
      showExtraInformation(state, showExtraInformation) {
        state.showExtraInformation = showExtraInformation;
      },
      setParams(state, params) {
        state.params = params;
      },
      setSelectedItems(state, selectedItems) {
        state.selectedItems.splice(
          0,
          state.selectedItems.length,
          ...selectedItems,
        );
      },
    },
  };


  return views;
}
