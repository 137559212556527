import { render, staticRenderFns } from "./TagChip.vue?vue&type=template&id=a85eabf4&scoped=true&"
import script from "./TagChip.vue?vue&type=script&lang=ts&"
export * from "./TagChip.vue?vue&type=script&lang=ts&"
import style0 from "./TagChip.vue?vue&type=style&index=0&id=a85eabf4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a85eabf4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_pointage_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a85eabf4')) {
      api.createRecord('a85eabf4', component.options)
    } else {
      api.reload('a85eabf4', component.options)
    }
    module.hot.accept("./TagChip.vue?vue&type=template&id=a85eabf4&scoped=true&", function () {
      api.rerender('a85eabf4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TagChip.vue"
export default component.exports