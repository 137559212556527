var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { dark: "", value: _vm.show },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "v-icon",
                { staticClass: "mr-2 qs-orange--text", attrs: { large: "" } },
                [_vm._v("mdi-email")]
              ),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("Modifier les courriels")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("qs-email-editor", {
        attrs: {
          theme: "labase",
          "allow-delete": _vm.userIsSuperadmin,
          items: _vm.editableItems
        },
        model: {
          value: _vm.editableItems,
          callback: function($$v) {
            _vm.editableItems = $$v
          },
          expression: "editableItems"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }