import globalViews from './globalViews';
import pointageCallCampaignsViews from './pointageCallCampaignsViews';
import tagsViews from './tagsViews';

const states = {
  globalViews,
  pointageCallCampaignsViews,
  tagsViews,
};

export default states;
