var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { value: _vm.tagsModal },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "v-icon",
                { staticClass: "mr-2 qs-orange--text", attrs: { large: "" } },
                [_vm._v("mdi-tag")]
              ),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("Ajouter une étiquette")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("tags-autocomplete", {
        attrs: { excluded: _vm.excluded },
        model: {
          value: _vm.selectedTagId,
          callback: function($$v) {
            _vm.selectedTagId = $$v
          },
          expression: "selectedTagId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }