import axios from 'axios';
import { Module } from 'vuex';

import { RootState } from 'qs_vuetify/src/types/states';

import { GlobalViewsState } from '@/types/states';

export const globalViews: Module<GlobalViewsState, RootState> = {
  namespaced: true,
  actions: {
    async pointageCallCampaigns({ commit }) {
      commit('pointageCallCampaignsLoaded', false);

      const ajax = axios.get('/pointage/call_campaigns', {
        params: {
          per_page: -1,
          for: 'calls',
          page: 1,
          fields: [
            'name',
            'start_at',
            'end_at',
            'description',
            'public',
            'stats.*',
            'instance.*',
            'users.id',
          ].join(','),
        },
      });

      const {
        data: {
          data: pointageCallCampaigns,
        },
      } = await ajax;

      commit('pointageCallCampaigns', pointageCallCampaigns);
      commit('pointageCallCampaignsLoaded', true);
    },
    async tags({ commit }, params) {
      const ajax = axios.get('/pointage/tags', {
        params,
      });

      const {
        data: {
          data: tags,
        },
      } = await ajax;

      commit('tags', tags);
    },
  },
  state: {
    pointageCallCampaigns: [],
    pointageCallCampaignsLoaded: false,
    loaded: false,
    tags: [],
  },
  getters: {
    pointageCallCampaigns(state) {
      return state.pointageCallCampaigns;
    },
    pointageCallCampaignsLoaded(state) {
      return state.pointageCallCampaignsLoaded;
    },
    loaded(state) {
      return state.loaded;
    },
    tags(state) {
      return state.tags;
    },
  },
  mutations: {
    pointageCallCampaigns(state, pointageCallCampaigns) {
      state.pointageCallCampaigns = pointageCallCampaigns;
    },
    pointageCallCampaignsLoaded(state, pointageCallCampaignsLoaded) {
      state.pointageCallCampaignsLoaded = pointageCallCampaignsLoaded;
    },
    loaded(state, loaded) {
      state.loaded = loaded;
    },
    tags(state, tags) {
      state.tags = tags;
    },
  },
};

export default globalViews;
