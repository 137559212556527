









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { User } from 'qs_vuetify/src/types/models';

const auth: any = namespace('auth');

@Component
export default class ContactFavoriteButton extends Vue {
  @Prop({ type: Number, required: true }) id!: number;

  @auth.Getter loading!: boolean;
  @auth.Getter user!: User;

  get isFavorite(): boolean {
    return this.user.favorites.map((f: { id: number }) => f.id).includes(this.id);
  }

  onCatchClick() {
    if (this.isFavorite) {
      this.deleteFavorite(this.id);
      return;
    }

    this.addFavorite(this.id);
  }

  async addFavorite(id: number) {
    await this.$store.dispatch('contacts/addFavorite', id);

    if (!this.user) {
      return;
    }

    this.$store.commit('auth/user', {
      ...this.user,
      favorites: [...this.user.favorites, { id }],
    });
  }

  async deleteFavorite(id: number) {
    await this.$store.dispatch('contacts/deleteFavorite', id);

    if (!this.user) {
      return;
    }

    this.$store.commit('auth/user', {
      ...this.user,
      favorites: this.user.favorites.filter((f: { id: number }) => f.id !== id),
    });
  }
}
