






































































































































































































































































































































































import Cookies from 'js-cookie';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsButton from 'qs_vuetify/src/components/QsButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import {
  PersistedContact,
  Email,
  Tag,
} from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import AddSpecificContactToCallCampaignModal from '@/components/Dialog/AddSpecificContactToCallCampaignModal.vue';
import ContactCommentsModal from '@/components/Dialog/ContactCommentsModal.vue';
import ContactContributions from '@/components/Contact/ContactContributions.vue';
import ContactEmailsModal from '@/components/Dialog/ContactEmailsModal.vue';
import ContactFavoriteButton from '@/components/Contact/ContactFavoriteButton.vue';
import ContactHistory from '@/components/Contact/ContactHistory.vue';
import ContactMemberships from '@/components/Contact/ContactMemberships.vue';
import ContactSources from '@/components/Contact/ContactSources.vue';
import ContactTagsModal from '@/components/Dialog/ContactTagsModal.vue';
import ContactVolunteerButton from '@/components/Contact/ContactVolunteerButton.vue';
import EditableTextField from 'qs_vuetify/src/components/EditableTextField.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';
import SelfIdentification from '@/components/SelfIdentification.vue';
import SingleLineInfo from '@/components/SingleLineInfo.vue';
import TagChip from '@/components/TagChip.vue';

import TagsMixin from '@/mixins/TagsMixin';

const contacts: any = namespace('contacts');
const tags: any = namespace('tags');
const view: any = namespace('contactsViews');

interface Interaction {
  color: string;
  date: number;
  icon: string;
  subtitle: string;
  supplementary: string;
  title: string;
  visible: string[];
}

@Component({
  components: {
    AddSpecificContactToCallCampaignModal,
    ContactCommentsModal,
    ContactContributions,
    ContactEmailsModal,
    ContactFavoriteButton,
    ContactHistory,
    ContactMemberships,
    ContactSources,
    ContactTagsModal,
    ContactVolunteerButton,
    EditableTextField,
    ItemNavigation,
    QsButton,
    SelfIdentification,
    SingleLineInfo,
    TagChip,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  TagsMixin,
) {
  @contacts.Getter data!: Array<PersistedContact>;
  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter item!: PersistedContact;
  @contacts.Getter loaded!: boolean;
  @contacts.Getter slug!: string;
  @contacts.Getter total!: number;
  @contacts.Mutation('item') syncItem!: any

  @tags.Getter('loaded') tagsLoaded!: boolean;

  @view.Getter commentsModal!: boolean;
  @view.Getter emailsModal !: boolean;
  @view.Getter contactExchangeModal!: boolean;
  @view.Getter params!: RestParams;
  @view.Getter tagsModal!: boolean;
  @view.Mutation setCommentsModal!: any;
  @view.Mutation setContactExchangeModal: any;
  @view.Mutation setEmailsModal!: any;
  @view.Mutation setParams!: any;
  @view.Mutation setTagsModal!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  viewParams = {
    contacts: {
      fields: [
        'address',
        'apartment',
        'birthdate',
        'city',
        'contact_exchanges.call_campaign_id',
        'created_at',
        ...[
          'answers',
          'called_at',
          'called_by_user.contact_name',
          'call_campaign.form_definition',
          'call_campaign.instance.name',
          'call_campaign.name',
          'comments',
          'left_message',
          'status',
        ].map((f) => `contact_exchanges.${f}`),
        'contributions.transaction.amount_in_cad',
        'contributions.date',
        'contributions.transaction.type',
        'contribution_current_year',
        'contribution_last_year',
        'district.name',
        'dpa',
        'emails.email',
        'emails.weight',
        'first_membership.period_start',
        'first_name',
        'gender',
        'home_phone',
        'last_membership.period_end',
        'last_membership.transaction.executed_at',
        'last_name',
        'main_language',
        'postal_code',
        'revisions.*',
        'revisions.created_by_user.contact_name',
        'status',
        'stripe_customer.*',
        'tags.name',
        'transactions.amount_in_cad',
        'transactions.executed_at',
        'transactions.type',
        'updated_at',
        'v1_contact_id',
      ].join(','),
    },
  };

  history = 'all';

  get bottomOffset(): number {
    return this.$vuetify.application.bottom;
  }

  async completeMembership() {
    await Cookies.set(
      'qs_labase_adhesion',
      {
        id: this.item.id,
        email: this.mainEmail ? this.mainEmail.email : '',
        first_name: this.item.first_name,
        last_name: this.item.last_name,
        address: this.item.address,
        apartment: this.item.apartment,
        city: this.item.city,
        postal_code: this.item.postal_code,
        home_phone: this.item.home_phone,
        gender: this.item.gender,
        birthdate: this.item.birthdate || '',
      },
      {
        expires: 1,
        secure: process.env.NODE_ENV !== 'development',
        domain: process.env.VUE_APP_COOKIE_DOMAIN,
        path: '/',
        sameSite: 'Lax',
      },
    );

    window.open(process.env.VUE_APP_ADHESION_URL, '_blank');
  }

  get mainEmail(): Email | null {
    if (this.item.emails.length < 1) {
      return null;
    }

    return this.item.emails[0];
  }

  get index(): number | null {
    const index = this.data.findIndex((i) => i.id === this.item.id);

    if (index === -1) {
      return null;
    }

    return index;
  }

  get nextContact(): PersistedContact | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index + 1 > this.data.length) {
      return null;
    }

    return this.data[this.index + 1];
  }

  get prevContact(): PersistedContact | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index - 1 < 0) {
      return null;
    }

    return this.data[this.index - 1];
  }

  get routeDataLoadedAndItemReady() {
    return this.routeDataLoaded && this.itemReady;
  }

  get tags() {
    const root = this.item.tags.filter((tag: Tag) => !tag.parent_id);


    for (let i = 0; i < root.length; i += 1) {
      root[i].children = this.item.tags.filter((child: Tag) => child.parent_id === root[i].id);
    }

    return root;
  }

  get topOffset(): number {
    return this.$vuetify.application.top;
  }

  get showEmailsModal() {
    return this.emailsModal;
  }

  set showEmailsModal(val: boolean) {
    this.setEmailsModal(val);
  }

  @Watch('itemReady')
  onItemReadyChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }

  async loadNextPageAndNavigate() {
    if (typeof this.params.page === 'number') {
      this.setParams({
        ...this.params,
        page: this.params.page + 1,
      });
      this.$store.commit('global/subtitle', 'Chargement...');
      this.$emit('updateHead');
      await this.$store.dispatch('contacts/loadPage', this.params);

      if (this.nextContact) {
        this.$router.push(`/contacts/${this.nextContact.id}`);
      }
    }
  }

  async patch(field: string) {
    await axios.put(`/contacts/${this.id}`, { [field]: this.item[field] });
    this.showUpdate();
  }

  // eslint-disable-next-line class-methods-use-this
  paymentMethod(method: string = ''): string {
    switch (method) {
      case 'cash':
        return 'en argent comptant';
      case 'cheque':
        return 'chèque';
      case 'dgeq':
        return 'via Élections Québec';
      case 'ppm':
        return 'mensuel (DPA)';
      case 'web':
      default:
        return 'web';
    }
  }

  async removeTag(tagId: number | string): Promise<any> {
    await this.deleteContactTag(this.id, tagId);

    this.showUpdate();
  }

  showUpdate(message = 'Contact mis à jour.') {
    this.reloadDataRoutesData();

    this.$store.commit('global/addNotification', {
      color: 'success',
      message,
    });
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', this.item?.full_name);
    }
    this.$emit('updateHead');
  }
}
