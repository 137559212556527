var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "base-item-card tag-card",
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex pb-1" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  }
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "flex-grow" }, [
            _c("h1", { staticClass: "text-h5 font-weight-bold" }, [
              _vm._v(_vm._s(_vm.item.name))
            ]),
            _c("span", { staticClass: "body-2" }, [
              _vm._v(
                _vm._s(_vm.item.instance ? _vm.item.instance.name : "Nationale")
              )
            ])
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _vm.showText
        ? _c("v-card-text", [
            _vm.item.created_at
              ? _c("div", [
                  _vm._v(" Créée "),
                  _vm.item.created_by_user
                    ? _c("span", [
                        _vm._v(
                          " par " +
                            _vm._s(_vm.item.created_by_user.contact_name) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    " le " +
                      _vm._s(_vm._f("datetime")(_vm.item.created_at)) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.item.updated_at
              ? _c("div", [
                  _vm._v(" Mise-à-jour "),
                  _vm.item.updated_by_user
                    ? _c("span", [
                        _vm._v(
                          " par " +
                            _vm._s(_vm.item.updated_by_user.contact_name) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    " le " +
                      _vm._s(_vm._f("datetime")(_vm.item.updated_at)) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.item.parent
              ? _c("div", { staticClass: "mt-3" }, [
                  _vm._v(" Incluse dans " + _vm._s(_vm.item.parent.name) + " ")
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }