import { render, staticRenderFns } from "./CallCampaignCard.vue?vue&type=template&id=391208bf&"
import script from "./CallCampaignCard.vue?vue&type=script&lang=ts&"
export * from "./CallCampaignCard.vue?vue&type=script&lang=ts&"
import style0 from "./CallCampaignCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCheckbox,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_pointage_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('391208bf')) {
      api.createRecord('391208bf', component.options)
    } else {
      api.reload('391208bf', component.options)
    }
    module.hot.accept("./CallCampaignCard.vue?vue&type=template&id=391208bf&", function () {
      api.rerender('391208bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CallCampaign/CallCampaignCard.vue"
export default component.exports