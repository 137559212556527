var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm.filterToSave
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" Sauvegarder un filtre ")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Nom du filtre" },
                        model: {
                          value: _vm.filterToSave.name,
                          callback: function($$v) {
                            _vm.$set(_vm.filterToSave, "name", $$v)
                          },
                          expression: "filterToSave.name"
                        }
                      }),
                      _vm.userIsSuperadmin
                        ? _c("v-checkbox", {
                            attrs: {
                              disabled: _vm.filterToSave.private,
                              label:
                                "Filtre national (visible par toutes les instances)"
                            },
                            model: {
                              value: _vm.allInstances,
                              callback: function($$v) {
                                _vm.allInstances = $$v
                              },
                              expression: "allInstances"
                            }
                          })
                        : _vm._e(),
                      _c("v-checkbox", {
                        attrs: {
                          disabled: !_vm.userHas("FILTERS_CREATE"),
                          label: "Filtre privé (visible seulement par moi)"
                        },
                        model: {
                          value: _vm.filterToSave.private,
                          callback: function($$v) {
                            _vm.$set(_vm.filterToSave, "private", $$v)
                          },
                          expression: "filterToSave.private"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "deep-orange", text: "" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [_vm._v(" Annuler ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "deep-orange",
                            text: "",
                            disabled: !_vm.filterToSave
                          },
                          on: { click: _vm.submitForm }
                        },
                        [_vm._v(" Sauvegarder ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }