export default {
  call_campaigns: {
    calls_to_do: 'Aucun appel à faire | {count} appel à faire | {count} appels à faire',
    done_exchanges: '<span class="num">Aucun</span> contacté | <span class="num">{count}</span> contacté | <span class="num">{count}</span> contactés',
    failed_exchanges: '<span class="num">Aucun</span> échoué | <span class="num">{count}</span> échoué | <span class="num">{count}</span> échoués',
    reserved_exchanges: '<span class="num">Aucun</span> réservé | <span class="num">{count}</span> réservé | <span class="num">{count}</span> réservés',
    users_affected: 'Aucun-e miliant-e affecté-e | {count} miliant-e affecté-e | {count} miliant-es affecté-es',
    unsub_phone: 'désabboner aux communications téléphoniques',
    sub_phone: 'abboner aux communications téléphoniques',
    unsub_email: 'désabboner aux communications courriels',
    sub_email: 'abboner aux communications courriels',
    did_not_answer: 'N\'a pas répondu',
    left_message: 'Laissé un message',
    add_tag: 'Ajouter une étiquette',
    script: 'Scénario d\'appel',
  },
  pointage: {
    elections: {
      title: 'Élection | Élections',
      new: 'Nouvelle',
    },
    electoral_lists: {
      title: 'Liste électorale | Listes électorales',
      new: 'Nouvelle',
    },
    parties: {
      title: 'Parti | Partis',
      new: 'Nouveau',
    },
    polling_places: {
      title: 'Bureau de vote | Bureaux de vote',
      new: 'Nouveau',
    },
    polling_sectors: {
      title: 'Secteur | Secteurs',
      new: 'Nouveau',
    },
    polling_subdivisions: {
      title: 'Section de vote | Sections de vote',
      new: 'Nouvelle',
    },
  },
  mail_campaigns: {
    clicked: 'Aucun clic | {count}% cliqué | {count}% cliqués',
    contacts: 'Aucun contact | {count} contact | {count} contacts',
    opened: 'Aucun ouvert | {count}% ouvert | {count}% ouverts',
  },
};
