











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Instance } from 'qs_vuetify/src/types/models';

const auth: any = namespace('auth');

@Component
export default class ContactVolunteerButton extends Vue {
  @Prop({ type: Number, required: true }) id!: number;

  @auth.Getter loading!: boolean;
  @auth.Getter('currentInstance') instance!: Instance;

  get isVolunteer(): boolean {
    return this.instance.active_volunteers
      .map((f: { contact_id: number }) => f.contact_id)
      .includes(this.id);
  }

  onCatchClick() {
    if (this.isVolunteer) {
      this.deleteVolunteer(this.id);
      return;
    }

    this.addVolunteer(this.id);
  }

  async addVolunteer(id: number) {
    const role = {
      instance_id: this.instance.id,
      contact_id: id,
    };

    await this.$store.dispatch('instances/addVolunteer', role);

    this.$store.commit('auth/addContactToInstanceRoles', role);
  }

  async deleteVolunteer(id: number) {
    const role = {
      instance_id: this.instance.id,
      contact_id: id,
    };

    await this.$store.dispatch('contacts/deleteVolunteer', role);

    this.$store.commit('auth/deleteContactToInstanceRoles', role);
  }
}
