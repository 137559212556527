





































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ContactListItem from '@/components/ContactListItem.vue';
import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { AppNotification } from 'qs_vuetify/src/types/components';
import { PersistedMailCampaign } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

import axios from 'qs_vuetify/src/plugins/axios';

const global: any = namespace('global');

@Component({
  components: {
    ContactListItem,
    QsActionModal,
    QsRelationField,
  },
})
export default class AddToMailCampaignModal extends Vue {
  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @Prop({ required: true, type: Array }) filters!: { name: string; options: any }[];
  @Prop({ required: true, type: Object }) params!: RestParams;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  dummyItem = {};
  loading: boolean = false;
  mailCampaign: PersistedMailCampaign | null = null;

  queryDefinition: any = {
    key: 'data',
    slug: 'mail_campaigns',
    text: 'name',
    value: 'id',
    params: {
      fields: [
        'description',
        'instance.name',
        'name',
      ].join(','),
    },
  }

  async submit(): Promise<void> {
    if (this.mailCampaign?.id) {
      this.loading = true;

      try {
        await axios.put('/jobs/contacts/add_to_mail_campaigns', {
          mail_campaigns: [this.mailCampaign.id],
        }, {
          params: {
            ...this.params,
            per_page: '*',
          },
        });

        this.addNotification({
          color: 'success',
          message: "Les destinataires ont été ajouté·es à l'envoi courriel",
        });

        this.syncedValue = false;
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: "Une erreur est survenue lors de l'ajout",
        });
      } finally {
        this.loading = false;
      }
    }
  }

  @Watch('syncedValue')
  onSyncedValueChanged(val: boolean) {
    if (!val) {
      this.dummyItem = {};
      this.mailCampaign = null;
      (this.$refs.relation as QsRelationField).clear();
    } else {
      this.$nextTick(() => {
        (this.$refs.relation as QsRelationField).focus();
      });
    }
  }
}
