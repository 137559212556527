var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "tags" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "3", cols: "12" } },
            [
              _c("qs-filters", {
                staticClass: "tags__filters",
                attrs: {
                  theme: "labase",
                  "active-filters": _vm.params,
                  "filters-definition": _vm.activeFilters,
                  "model-name": _vm.slug
                },
                on: { input: _vm.updateFilters }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "9", cols: "12" } },
            [
              _c("qs-list", {
                attrs: {
                  error: _vm.error,
                  items: _vm.data,
                  loading: _vm.loading,
                  "model-name": _vm.slug,
                  selection: _vm.selectedItems,
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage, select: _vm.selectAll },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("tag-card", {
                          attrs: {
                            item: item,
                            selected: _vm.isSelected(item),
                            "show-text": _vm.showExtraInformation,
                            to: _vm.tagCardTo(item)
                          },
                          on: {
                            check: function($event) {
                              return _vm.updateSelection($event, item)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les informations étendues"
                                  },
                                  model: {
                                    value: _vm.showExtraInformation,
                                    callback: function($$v) {
                                      _vm.showExtraInformation = $$v
                                    },
                                    expression: "showExtraInformation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view", { attrs: { "model-name": "tags" } }),
      _c("tags-filters", {
        model: {
          value: _vm.showFilterModal,
          callback: function($$v) {
            _vm.showFilterModal = $$v
          },
          expression: "showFilterModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }