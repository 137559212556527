











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { CallCampaignStats } from 'qs_vuetify/src/types/models';

@Component
export default class CallCampaignProgress extends Vue {
  @Prop({ type: Boolean, required: false }) combined!: boolean;
  @Prop({ type: Object, required: true }) data!: CallCampaignStats;

  get done(): number {
    if (!this.data || !this.data.done_contact_exchanges || !this.data.contacts) {
      return 0;
    }

    return Math.min((this.data.done_contact_exchanges / this.data.contacts) * 100, 100);
  }

  get failed(): number {
    if (!this.data || !this.data.failed_contact_exchanges || !this.data.contacts) {
      return 0;
    }

    return Math.min((this.data.failed_contact_exchanges / this.data.contacts) * 100, 100);
  }

  get reserved(): number {
    if (!this.data || !this.data.reserved_contact_exchanges || !this.data.contacts) {
      return 0;
    }

    return Math.min((this.data.reserved_contact_exchanges / this.data.contacts) * 100, 100);
  }

  get unassigned(): number {
    if (!this.data
      || this.data.unassigned_contacts === undefined
      || this.data.contacts === undefined) {
      return 0;
    }

    if (this.data.contacts === 0) {
      return 100;
    }

    return Math.min((this.data.unassigned_contacts / this.data.contacts) * 100, 100);
  }

  get progress(): number {
    return (this.done + this.failed + this.reserved);
  }
}
