var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "base-item-card",
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex pb-1" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  }
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "flex-grow" }, [
            _c("h1", { staticClass: "text-h5 font-weight-bold" }, [
              _vm._v(_vm._s(_vm.item.name || _vm.item.date))
            ])
          ]),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }