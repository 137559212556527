


















import Component from 'vue-class-component';

import BaseItemCard from '@/components/BaseItemCard.vue';
import { Model } from 'qs_vuetify/src/types/models';

@Component
export default class ModelCard extends BaseItemCard<Model & { name: string }> {

}
