





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('tags');
const view: any = namespace('tagsViews');

@Component({
  components: {
    QsActionModal,
  },
})
export default class TagsFilters extends Vue {
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter slug!: string;
  @view.Getter params!: RestParams;

  @Prop({ required: true, type: Boolean }) value!: boolean;
}
