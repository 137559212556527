








































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import {
  ContactExchange,
  PersistedContactComment,
  PersistedContact,
  PersistedContribution,
  PersistedTransaction,
} from 'qs_vuetify/src/types/models';

import CallCampaignAnswers from '@/components/CallCampaign/CallCampaignAnswers.vue';
import QsLoadingIndicator from 'qs_vuetify/src/components/Indicators/QsLoadingIndicator.vue';
import { namespace } from 'vuex-class';

interface Interaction {
  author?: string;
  category: string;
  color: string;
  date: string;
  icon: string;
  subtitle: string;
  subtitleClass?: string;
  supplementary?: string;
  title: string;
}

const contact_comments: any = namespace('contact_comments');

@Component({
  components: {
    CallCampaignAnswers,
    QsLoadingIndicator,
  },
})
export default class ContactHistory extends mixins(
  I18nMixin,
) {
  @Prop({ required: false, type: Object }) item!: PersistedContact | null;
  @contact_comments.Getter getCommentsByContact!: (id: number) => Array<PersistedContactComment>;

  @contact_comments.Getter('loaded') commentsLoaded!: boolean;

  modes: string[] = ['all'];

  get interactions(): Array<Interaction> {
    if (!this.item) {
      return [];
    }

    const { modes } = this;
    const contributions = this.item.contributions
      ? this.item.contributions.map((c: PersistedContribution) => ({
        color: 'qs-orange',
        date: new Date(c.date).getTime(),
        icon: '$qs-membership',
        subtitle: `Paiement ${this.paymentMethod(c.transaction.type)}`,
        supplementary: this.$filters.currency(`${c.transaction.amount_in_cad}`),
        title: 'Contribution',
        category: 'memberships',
      }))
      : [];

    const memberships = this.item.transactions
      ? this.item.transactions.map((t: PersistedTransaction) => ({
        color: 'qs-orange',
        date: new Date(t.executed_at || '').getTime(),
        icon: '$qs-membership',
        subtitle: `Paiement ${this.paymentMethod(t.type)}`,
        supplementary: this.$filters.currency(`${t.amount_in_cad}`),
        title: 'Adhésion',
        category: 'memberships',
      }))
      : [];

    const revisions: Array<Interaction> = this.item.revisions
      .reduce((acc: Array<Interaction>, revision: any) => {
        const afterKeys = Object.keys(revision.after);
        afterKeys.forEach((key) => {
          if (revision.after[key]) {
            acc.push({
              color: 'qs-dark-blue',
              date: revision.updated_at,
              icon: 'mdi-account',
              title: this.labelOrName(key),
              author: revision?.created_by_user?.contact_name || '',
              subtitle: this.$filters.revisions(revision.after[key], 'contact', key),
              category: 'account',
            });
          }

          if (revision.before[key]) {
            acc.push({
              color: 'qs-dark-blue',
              date: revision.updated_at,
              icon: 'mdi-account',
              title: this.labelOrName(key),
              author: revision?.created_by_user?.full_name || '',
              subtitle: this.$filters.revisions(revision.before[key], 'contact', key),
              subtitleClass: 'text-decoration-line-through',
              category: 'account',
            });
          }
        });

        return acc;
      }, []);

    const exchanges: Array<Interaction> = this.item.contact_exchanges
      .filter((ce: ContactExchange) => !!ce.called_at)
      .map((ce: ContactExchange) => ({
        color: '#007843',
        date: ce.called_at,
        icon: 'mdi-message',
        title: `${ce.call_campaign?.name || 'Campagne'}
          (${ce.call_campaign?.instance?.name || 'Instance'})`,
        subtitle: 'Échange',
        category: 'comments',
        answers: ce.answers,
        comments: ce.comments,
        form_definition: ce.call_campaign?.form_definition,
      }));

    const comments = this.getCommentsByContact(this.item.id).map((comment) => ({
      color: '#007843',
      date: new Date(comment.created_at).getTime(),
      icon: 'mdi-message',
      subtitle: comment.comment,
      title: 'Commentaire',
      category: 'comments',
    }));

    return [
      ...contributions,
      ...memberships,
      ...revisions,
      ...exchanges,
      ...comments,
    ]
      .filter((i) => modes.includes('all') || modes.includes(i.category))
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  // eslint-disable-next-line class-methods-use-this
  paymentMethod(method: string = ''): string {
    switch (method) {
      case 'cash':
        return 'en argent comptant';
      case 'cheque':
        return 'chèque';
      case 'dgeq':
        return 'via Élections Québec';
      case 'ppm':
        return 'mensuel (DPA)';
      case 'web':
      default:
        return 'web';
    }
  }

  @Watch('modes')
  onModesChanged(newModes: string[], oldModes: string[]) {
    if (newModes.length === 0) {
      this.modes = ['all'];
    }

    if (newModes.length > 1 && newModes.includes('all')) {
      if (oldModes.includes('all')) {
        this.modes = this.modes.filter((m: string) => m !== 'all');
      } else {
        this.modes = ['all'];
      }
    }
  }

  created() {
    this.fetchComments();
  }

  @Watch('item.id')
  fetchComments() {
    if (this.item) {
      this.$store.dispatch('contact_comments/index', {
        per_page: -1,
        prefix: `/contacts/${this.item.id}`,
      });
    }
  }
}
