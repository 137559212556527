var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              !_vm.itemReady
                ? _c("span", [_vm._v("Chargement...")])
                : _vm.item.id
                ? _c("span", [
                    _vm._v(_vm._s(_vm.item.name || "Étiquette " + _vm.item.id))
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.item.name || "Nouvelle étiquette"))
                  ])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c("v-btn", { on: { click: _vm.submitForm } }, [
                _vm._v(" Enregistrer ")
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.modal,
        callback: function($$v) {
          _vm.modal = $$v
        },
        expression: "modal"
      }
    },
    [
      _vm.itemReady && !_vm.loading
        ? _c("qs-form-builder", {
            attrs: {
              error: _vm.error,
              form: _vm.form,
              order: _vm.formOrder,
              item: _vm.item,
              "model-name": _vm.slug
            },
            on: { input: _vm.syncItem }
          })
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "30vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }