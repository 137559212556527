var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "call-campaign-form", attrs: { fluid: "" } },
    [
      _c("item-navigation", {
        staticClass: "my-0",
        attrs: {
          item: _vm.item,
          items: _vm.data,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _vm.itemReady
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Configuration")]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c("qs-form-builder", {
                                attrs: {
                                  form: _vm.form,
                                  order: _vm.formOrder,
                                  item: _vm.item,
                                  "model-name": _vm.slug
                                },
                                on: { input: _vm.syncItem }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4132300367
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c("qs-card", {
                    staticClass: "mb-6",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Progression "),
                              _c("v-spacer"),
                              _c(
                                "qs-button",
                                {
                                  attrs: {
                                    to:
                                      "/call_campaigns/" +
                                      _vm.item.id +
                                      "/contact_exchanges/next"
                                  }
                                },
                                [_vm._v(" Commencer les appels ")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c("call-campaign-progress", {
                                attrs: { data: _vm.stats }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3233484783
                    )
                  }),
                  _c("qs-card", {
                    staticClass: "mb-6",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Script d'appel")]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c("qs-html-editor", {
                                staticClass: "mt-4",
                                model: {
                                  value: _vm.item.script,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "script", $$v)
                                  },
                                  expression: "item.script"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2932662161
                    )
                  }),
                  _c("qs-form-editor", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.item.status !== "draft",
                      title: "Questions",
                      templates: _vm.templates
                    },
                    model: {
                      value: _vm.item.form_definition,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "form_definition", $$v)
                      },
                      expression: "item.form_definition"
                    }
                  })
                ],
                1
              ),
              _vm.itemReady && _vm.userHas("CALL_CAMPAIGN_USERS_RETRIEVE")
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3" } },
                    [
                      _c("qs-card", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(" Militant·es "),
                                  _c("v-spacer"),
                                  _vm.userHas("CALL_CAMPAIGN_USERS_UPDATE")
                                    ? _c(
                                        "qs-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.showCallCampaignUsersModal = true
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm.item &&
                                  _vm.item.users &&
                                  _vm.item.users.length > 0
                                    ? _c(
                                        "v-list",
                                        _vm._l(_vm.item.users, function(user) {
                                          return _c(
                                            "v-list-item",
                                            { key: user.id },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          user.contact_name ||
                                                            user.email
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(user.email) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _vm.userHas(
                                                    "CALL_CAMPAIGN_USERS_DELETE"
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            loading:
                                                              user.loading,
                                                            text: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: [
                                                              function($event) {
                                                                return _vm.deleteCallCampaignUser(
                                                                  user
                                                                )
                                                              },
                                                              function($event) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                              }
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "font-italic text-center"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Pas de militant·es affecté·es. "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3598949189
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "40vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm.itemReady
        ? _c(
            "v-row",
            { staticClass: "call-campaign-form__contacts" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Destinataires "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    194678707
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsCsv }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsXls }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.userHas("CALL_CAMPAIGN_CONTACTS_UPDATE")
                                ? _c(
                                    "qs-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.showCallCampaignContactsModal = true
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "3" } },
                                    [
                                      _c("qs-filters", {
                                        attrs: {
                                          "keep-open": "",
                                          theme: "labase",
                                          "active-filters": _vm.contactsParams,
                                          "filters-definition":
                                            _vm.contactsFiltersSubset,
                                          "model-name": "contacts"
                                        },
                                        on: { input: _vm.updateContactsFilters }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "9" } },
                                    [
                                      _c("qs-data-table", {
                                        attrs: {
                                          actions: ["view", "delete"],
                                          headers: _vm.contactsHeaders,
                                          items: _vm.contacts,
                                          loading:
                                            !_vm.routeDataLoaded ||
                                            _vm.contactsLoading,
                                          options: _vm.contactsOptions,
                                          "set-options": _vm.setContactsOptions,
                                          total: _vm.contactsTotal || 0
                                        },
                                        on: {
                                          view: function($event) {
                                            return _vm.goTo({
                                              name: "ContactForm",
                                              params: { id: $event.id }
                                            })
                                          },
                                          delete: _vm.deleteCallCampaignContact
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.contact_exchanges",
                                              fn: function(ref) {
                                                var contact = ref.item
                                                return [
                                                  contact &&
                                                  contact.contact_exchanges
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm._l(
                                                            _vm.groupContactExchangesByStatus(
                                                              contact.contact_exchanges
                                                            ),
                                                            function(
                                                              exchanges,
                                                              status
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: status },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "models.contact_exchanges.enums.status." +
                                                                                status
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(": "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        exchanges.length
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.filterExchangesByV1ContactId(
                                                                    contact.v1_contact_id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Voir les échanges "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _c("div", [
                                                        _c("small", [
                                                          _c("em", [
                                                            _vm._v(
                                                              "Aucun échange."
                                                            )
                                                          ])
                                                        ])
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          824753641
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      480735272
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemReady && _vm.userHas("CONTACT_EXCHANGES_RETRIEVE")
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Échanges "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    194678707
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: {
                                            click: _vm.exportContactExchangesCsv
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: {
                                            click: _vm.exportContactExchangesXls
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "3" } },
                                    [
                                      _c("qs-filters", {
                                        attrs: {
                                          "keep-open": "",
                                          theme: "labase",
                                          "active-filters":
                                            _vm.contactExchangesParams,
                                          "filters-definition":
                                            _vm.contactExchangesFiltersDefinition,
                                          schemas: {
                                            answers: _vm.item.form_definition
                                          },
                                          "model-name": "contact_exchanges"
                                        },
                                        on: {
                                          input:
                                            _vm.updateContactExchangesFilters
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", lg: "9" } },
                                    [
                                      _c("qs-data-table", {
                                        attrs: {
                                          actions: [],
                                          headers: _vm.contactExchangesHeaders,
                                          items: _vm.contactExchanges,
                                          loading:
                                            !_vm.routeDataLoaded ||
                                            _vm.contactExchangesLoading,
                                          options: _vm.contactExchangesOptions,
                                          "set-options":
                                            _vm.setContactExchangesOptions,
                                          total: _vm.contactExchangesTotal || 0
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.left_message",
                                              fn: function(ref) {
                                                var contact = ref.item
                                                return [
                                                  contact.status === "failed" ||
                                                  (contact.status ===
                                                    "completed" &&
                                                    _vm.item.leave_message)
                                                    ? _c(
                                                        "qs-boolean-indicator",
                                                        {
                                                          attrs: {
                                                            value: !!contact.left_message
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.status",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "models.contact_exchanges.enums.status." +
                                                            item.status
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.answers",
                                              fn: function(ref) {
                                                var answers = ref.item.answers
                                                return [
                                                  _c("call-campaign-answers", {
                                                    attrs: {
                                                      answers: answers,
                                                      schema:
                                                        _vm.item.form_definition
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          576260346
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2975422515
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("call-campaign-users-modal", {
        attrs: { id: _vm.id, value: _vm.showCallCampaignUsersModal },
        on: {
          "update:value": function($event) {
            _vm.showCallCampaignUsersModal = $event
          },
          input: function($event) {
            _vm.showCallCampaignUsersModal = $event
          },
          added: function($event) {
            return _vm.reloadDataRoutesData(["call_campaigns.retrieve"])
          }
        }
      }),
      _c("campaign-contacts-modal", {
        attrs: {
          value: _vm.showCallCampaignContactsModal,
          loading: _vm.modalLoading
        },
        on: {
          "update:value": function($event) {
            _vm.showCallCampaignContactsModal = $event
          },
          add: _vm.addCallCampaignContact
        }
      }),
      _c("qs-confirmation-modal", {
        attrs: { title: _vm.dialog.title, message: _vm.dialog.message },
        on: { confirm: _vm.dialog.callback },
        model: {
          value: _vm.dialog.value,
          callback: function($$v) {
            _vm.$set(_vm.dialog, "value", $$v)
          },
          expression: "dialog.value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }