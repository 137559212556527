var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.accesibleTags,
      placeholder: _vm.placeholder,
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", [_vm._v(_vm._s(item.name))]),
                _c("v-list-item-subtitle", [_vm._v(_vm._s(item.description))])
              ],
              1
            ),
            _c("v-list-item-action-text", [
              _vm._v(
                " " +
                  _vm._s(item.instance ? item.instance.name : "Nationale") +
                  " "
              )
            ])
          ]
        }
      }
    ]),
    model: {
      value: _vm.syncedVal,
      callback: function($$v) {
        _vm.syncedVal = $$v
      },
      expression: "syncedVal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }