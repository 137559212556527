


























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';

import { PersistedTag as Tag } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import TagCard from '@/components/Tag/TagCard.vue';
import TagsFilters from '@/components/Dialog/TagsFilters.vue';

const auth: any = namespace('auth');
const global: any = namespace('global');
const tags: any = namespace('tags');
const view: any = namespace('tagsViews');

@Component({
  components: {
    QsFilters,
    QsList,
    TagCard,
    TagsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Tags extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  LazyListMixin,
) {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: Function;

  @tags.Getter data!: Array<Tag>;
  @tags.Getter error!: ErrorResponse;
  @tags.Getter exportUrl!: string;
  @tags.Getter filtersDefinition!: FiltersDefinition | null;
  @tags.Getter loading!: boolean;
  @tags.Getter slug!: string;
  @tags.Getter total!: number;

  @view.Getter selectedFilterIndex!: number;
  @view.Mutation setSelectedFilterIndex!: (index: number) => void;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<Tag>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    per_page: 20,
    fields: [
      '*',
      'instance.name',
      'parent.name',
      'created_by_user.contact_name',
      'updated_by_user.contact_name',
    ].join(','),
  };

  get viewParams() {
    return {
      tags: this.defaultParams,
    };
  }

  showFilterModal = false;
  filtersLoaded = true;
  viewStoreName = 'tagsViews';

  mounted() {
    this.setAction();

    this.$store.commit('tags/exportFields', [
      '*',
    ]);

    if (this.data.length === 0) {
      this.setParams({
        ...this.defaultParams,
        ...this.params,
        page: 1,
      });
    }
  }

  get activeFilters(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    return {
      'instance.id': this.filtersDefinition['instance.id'],
      'parent.id': this.filtersDefinition['parent.id'],
      q: this.filtersDefinition.q,
    };
  }

  tagCardTo(item: Tag) {
    if (this.userIsSuperadmin
      || (item.instance_id === this.instanceId && this.userHas('TAGS_UPDATE'))) {
      return { name: 'TagDialog', params: { id: item.id } };
    }

    return null;
  }

  @Watch('$route', { deep: true })
  setAction() {
    this.$store.commit('global/actions', [
      {
        onClick: () => { this.$router.push('tags/new'); },
        color: 'primary',
        icon: 'mdi-plus',
      },
    ]);
  }
}

