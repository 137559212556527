var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      staticClass: "mr-2 mb-2 font-weight-medium rounded-lg qs-tag-chip",
      staticStyle: { width: "fit-content" },
      attrs: { label: "", close: _vm.close, "close-icon": "mdi-close" },
      on: {
        "click:close": function($event) {
          return _vm.$emit("click:close")
        }
      }
    },
    [_vm._v(" " + _vm._s(_vm.name) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }