var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { "max-width": 650, value: _vm.contactExchangeModal },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "v-icon",
                { staticClass: "mr-2 qs-orange--text", attrs: { large: "" } },
                [_vm._v("$qs-phone")]
              ),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("Ajouter le contact à une campagne d'appel")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(" Échanger ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("call-campaigns-autocomplete", {
        attrs: { excluded: _vm.excludedCampaigns },
        model: {
          value: _vm.selectedCallCampaign,
          callback: function($$v) {
            _vm.selectedCallCampaign = $$v
          },
          expression: "selectedCallCampaign"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }