// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/* Couleurs dans la charte UI */\n/* Titres, icônes, blocs éditable, fond du header */\n/* Tags primaires, textes */\n/* Hover d'accent */\n/* Accent, Réussite */\n/* Appels non affectés */\n/* Boutons désactivés */\n/* Hover de primaire */\n/* Focus des champs */\n/* Primaire */\n/* Événements */\n/* Erreur */\n/* Avertissement */\n/* Nomenclature Vuetify */\n/* Autre couleurs */\n.contacts__filters {\n  max-height: 80vh;\n  overflow: auto;\n}\n.contacts .sticky {\n  position: sticky;\n  top: 76px;\n  z-index: 2;\n}", ""]);
// Exports
exports.locals = {
	"qs-accent--hover": "#28e1b5",
	"qs-accent": "#21c39c",
	"qs-anchor": "#ff5505",
	"qs-blue": "#2d258b",
	"qs-dark-blue": "#100c47",
	"qs-error": "#dd0122",
	"qs-green--hover": "#28e1b5",
	"qs-green": "#21c39c",
	"qs-grey": "#f4f4f4",
	"qs-info": "#100c47",
	"qs-light-blue": "#c6c2d6",
	"qs-light-blue--hover": "#b5abdc",
	"qs-light-grey": "#f4f3f7",
	"qs-orange--hover": "#ff7737",
	"qs-orange--lighter": "#ffccb4",
	"qs-orange": "#ff5505",
	"qs-pink": "#eca3c1",
	"qs-primary--hover": "#ff7737",
	"qs-primary": "#ff5505",
	"qs-red": "#dd0122",
	"qs-success": "#21c39c",
	"qs-warning": "#ffb400",
	"qs-yellow": "#ffb400"
};
module.exports = exports;
