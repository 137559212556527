var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { class: _vm.dataStoreName + "-list" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "3", cols: "12" } },
            [
              _c("qs-filters", {
                staticClass: "tags__filters",
                attrs: {
                  theme: "labase",
                  "active-filters": _vm.params,
                  "filters-definition": { q: { type: "text" } },
                  "model-name": _vm.slug
                },
                on: { input: _vm.updateFilters }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "9", cols: "12" } },
            [
              _c("qs-list", {
                attrs: {
                  items: _vm.items,
                  loading: _vm.loading,
                  "hide-selection": true,
                  "model-name": _vm.slug
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("model-card", {
                            attrs: { item: item, to: _vm.cardTo(item) },
                            on: {
                              check: function($event) {
                                return _vm.updateSelection($event, item)
                              }
                            }
                          })
                        ]
                      }
                    },
                    _vm.hasSelection
                      ? {
                          key: "actions",
                          fn: function() {
                            return [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "qs-btn",
                                        {
                                          attrs: {
                                            icon: "mdi-delete",
                                            color: "white"
                                          },
                                          on: { click: _vm.showDeleteDialog }
                                        },
                                        [_vm._v(" Supprimer ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view", {
        attrs: {
          "data-store-name": _vm.dataStoreName,
          "view-store-name": _vm.viewStoreName
        }
      }),
      _c("qs-confirm-dialog", {
        attrs: {
          persistent: false,
          prompt: "Voulez-vous vraiment supprimer les items sélectionnés",
          cancelText: "Non",
          confirmText: "Oui",
          onCancel: function() {
            return (_vm.endDialog = false)
          },
          onConfirm: _vm.confirmDeleteSelectedItems
        },
        model: {
          value: _vm.endDialog,
          callback: function($$v) {
            _vm.endDialog = $$v
          },
          expression: "endDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }