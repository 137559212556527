


























































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { Prop } from 'vue-property-decorator';

import { Model, Persisted } from 'qs_vuetify/src/types/models';

const global: any = namespace('global');

@Component
export default class ItemNavigation<M extends Persisted<Model>> extends Vue {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @Prop({ required: false, type: Function, default: undefined }) callback?: any;
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: false, type: Object, default: undefined }) item?: M;
  @Prop({ required: true, type: Array }) items!: M[];
  @Prop({ required: false, type: Number, default: 0 }) total!: number;

  get canLoadNext(): boolean {
    return !this.next && !!this.callback;
  }

  get index(): number | null {
    if (!this.item) {
      return null;
    }

    const index = this.items.findIndex((i) => i.id === this.item?.id);

    if (index === -1) {
      return null;
    }

    return index;
  }

  get navigateBackLabel(): string {
    if (this.previousLocation) {
      return "Retour à l'écran précédent";
    }
    return 'Retour à la liste';
  }

  get next(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index + 1 > this.items.length) {
      return null;
    }

    return this.items[this.index + 1];
  }

  get prev(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index - 1 < 0) {
      return null;
    }

    return this.items[this.index - 1];
  }

  navigateBack() {
    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else {
      this.$router.push(`/${this.modelName}`);
    }
  }
}
