var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "v-btn",
        {
          staticClass: "contact-volunteer-button ml-6",
          attrs: { icon: "", text: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onCatchClick($event)
            }
          }
        },
        [
          _vm.loading
            ? _c("v-icon", { attrs: { color: "accent" } }, [
                _vm._v("mdi-hand-left")
              ])
            : _vm.isVolunteer
            ? _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-hand-left")
              ])
            : _c("v-icon", [_vm._v("mdi-hand-left")])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }