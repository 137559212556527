





















import { PersistedTag } from 'qs_vuetify/src/types/models';
import { namespace } from 'vuex-class';
import Component, { mixins } from 'vue-class-component';
import { ModelSync, Prop } from 'vue-property-decorator';
import TagsMixin from '@/mixins/TagsMixin';

const globalViews: any = namespace('globalViews');

@Component
export default class ContactTagsModal extends mixins(TagsMixin) {
  @Prop({ required: false, type: Array, default: () => [] }) excluded!: PersistedTag[];
  @Prop({ required: false, type: String }) placeholder!: string;

  @ModelSync('value', 'change', { type: [Number, Object] }) syncedVal!: number | null;

  @globalViews.Getter tags!: Array<PersistedTag>;

  get accesibleTags(): PersistedTag[] {
    if (this.excluded.length > 0) {
      return this.tags
        .filter((t) => !this.excluded
          .map((e) => e.id)
          .includes(t.id)
          && !this.isReservedTag(t));
    }

    return this.tags;
  }

  mounted() {
    this.$store.dispatch('tags/index');
  }
}
