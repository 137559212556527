var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4 pa-0",
              attrs: {
                fab: "",
                color: "#FF5505",
                elevation: "0",
                small: "",
                link: "",
                tile: ""
              },
              on: { click: _vm.navigateBack }
            },
            [
              _c(
                "v-icon",
                { staticClass: "white--text", attrs: { small: "" } },
                [_vm._v("$qs-left-arrow")]
              )
            ],
            1
          ),
          _c("span", { staticClass: "text-h6" }, [
            _vm._v(_vm._s(_vm.navigateBackLabel))
          ])
        ],
        1
      ),
      _vm.total > 0 && !_vm.previousLocation
        ? _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "#FF5505",
                    disabled: !_vm.prev,
                    link: "",
                    tile: "",
                    fab: "",
                    small: "",
                    elevation: "0",
                    to:
                      "/" + _vm.modelName + "/" + (_vm.prev ? _vm.prev.id : "")
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { small: "" } },
                    [_vm._v("$qs-left-arrow")]
                  )
                ],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(_vm._f("number")(_vm.index + 1)) +
                  " / " +
                  _vm._s(_vm._f("number")(_vm.total)) +
                  " "
              ),
              _vm.canLoadNext
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "#FF5505",
                        link: "",
                        tile: "",
                        small: "",
                        fab: "",
                        elevation: "0"
                      },
                      on: {
                        click: function($event) {
                          return _vm.callback()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { small: "" } },
                        [_vm._v("$qs-right-arrow")]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "#FF5505",
                        disabled: !_vm.next,
                        link: "",
                        tile: "",
                        fab: "",
                        small: "",
                        elevation: "0",
                        to:
                          "/" +
                          _vm.modelName +
                          "/" +
                          (_vm.next ? _vm.next.id : "")
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { small: "" } },
                        [_vm._v("$qs-right-arrow")]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }