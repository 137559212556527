var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "contact-history d-flex flex-column",
      attrs: { flat: "", tile: "", height: "100%" }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "text-h2 text-uppercase font-weight-bold" },
        [_vm._v(" Historique ")]
      ),
      _c(
        "v-card-text",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "qs-btn-toggle",
            {
              attrs: { dense: "", multiple: "" },
              model: {
                value: _vm.modes,
                callback: function($$v) {
                  _vm.modes = $$v
                },
                expression: "modes"
              }
            },
            [
              _c(
                "v-btn",
                { attrs: { value: "account", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-account")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "memberships", icon: "" } },
                [_c("v-icon", [_vm._v("$qs-membership")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "comments", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-message")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "tags", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-tag")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "all", icon: "" } },
                [_c("v-icon", [_vm._v("mdi-apps")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-text", { staticClass: "d-flex flex-column flex-grow-1" }, [
        _c(
          "div",
          {
            staticClass: "flex-grow-1 mb-2",
            staticStyle: { position: "relative" }
          },
          [
            _c(
              "div",
              { staticClass: "contact-interactions" },
              [
                _vm.item && _vm.commentsLoaded
                  ? _c(
                      "v-list",
                      { staticClass: "flex-grow-1" },
                      _vm._l(_vm.interactions, function(item, index) {
                        return _c(
                          "v-list-item",
                          { key: index },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: "" + item.color,
                                      size: "21.3"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.icon) + " ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "font-weight-bold text-uppercase"
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                                _c(
                                  "v-list-item-subtitle",
                                  { class: "" + (item.subtitleClass || "") },
                                  [_vm._v(" " + _vm._s(item.subtitle) + " ")]
                                ),
                                _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.date,
                                          "D MMMM YYYY à HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                item.author
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(" " + _vm._s(item.author) + " ")
                                    ])
                                  : _vm._e(),
                                item.comments
                                  ? _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "comments" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.comments) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.answers && item.form_definition
                                  ? _c(
                                      "div",
                                      [
                                        _c("call-campaign-answers", {
                                          attrs: {
                                            answers: item.answers,
                                            schema: item.form_definition
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            item.supplementary
                              ? _c(
                                  "v-list-item-action-text",
                                  {
                                    staticClass:
                                      "body-1 font-weight-bold text--primary"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.supplementary) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _c("qs-loading-indicator")
              ],
              1
            )
          ]
        ),
        _vm.item
          ? _c(
              "div",
              { staticClass: "flex-shrink-1 text-center" },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }