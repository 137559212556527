var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        value: _vm.syncedValue,
        "content-class": "add-to-mail-campaign-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Ajouter des destinataires ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [_vm._v(" À un envoi courriel ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.mailCampaign === null,
                    loading: _vm.loading
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("qs-relation-field", {
        ref: "relation",
        attrs: {
          item: _vm.dummyItem,
          label: "Recherche",
          name: "add_contacts_to_mail_campaign",
          query: _vm.queryDefinition,
          value: _vm.mailCampaign
        },
        on: {
          input: function($event) {
            _vm.mailCampaign = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }