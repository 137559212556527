























import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

import QsButton from 'qs_vuetify/src/components/QsButton.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import TagsMixin from '@/mixins/TagsMixin';

import { PersistedContact, PersistedTag } from 'qs_vuetify/src/types/models';
import TagsAutocomplete from '../Tag/TagsAutocomplete.vue';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const globalViews: any = namespace('globalViews');

const contactsViews: any = namespace('contactsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFilters,
    TagsAutocomplete,
    QsRelationField,
  },
})
export default class ContactTagsModal extends mixins(DataRouteGuards, TagsMixin) {
  @auth.Getter instanceId!: number;
  @contacts.Getter item!: PersistedContact;
  @globalViews.Getter tags!: Array<PersistedTag>;

  @contactsViews.Getter tagsModal!: boolean;
  @contactsViews.Mutation setTagsModal: any;

  @Prop({ required: false, type: Array, default: () => [] }) excluded!: PersistedTag[];
  @Prop({ required: true, type: [Number, String] }) id!: number | string;
  @Prop({ required: false, type: Object, default: {} }) viewParams?: any;

  selectedTagId: number | null = null;
  queryDefinition: any = {}

  closeModal(): void {
    this.setTagsModal(false);
  }

  async submitForm(): Promise<void> {
    if (this.selectedTagId) {
      await this.putContactTag(this.id, this.selectedTagId);

      this.closeModal();
      this.reloadDataRoutesData();
    }

    this.$emit('click:close');
    this.$emit('input', false);
  }
}
