







































import { Filter } from 'qs_vuetify/src/types/models';
import Component, { mixins } from 'vue-class-component';
import { ModelSync, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { AppNotification } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');

@Component({
  components: {},
})
export default class FilterSaveModal extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @Prop({ type: Object, required: false }) filter!: Filter | null;
  @ModelSync('value', 'change', { type: Boolean }) show!: boolean;

  filterToSave: Filter | null = null;
  readonly slug = 'filters'

  get allInstances() {
    return !this.filterToSave?.instance_id && !this.filterToSave?.private;
  }

  set allInstances(value: boolean) {
    if (!this.filterToSave) {
      return;
    }

    if (value) {
      this.filterToSave.instance_id = null;
    } else {
      this.filterToSave.instance_id = this.instanceId;
    }
  }

  async submitForm() {
    if (!this.filterToSave) {
      return;
    }

    try {
      await this.$store.dispatch(
        this.filterToSave.id ? 'filters/update' : 'filters/create',
        {
          id: this.filterToSave.id,
          data: this.filterToSave,
        },
      );

      this.show = false;

      if (this.$store.state.filters.item && this.$store.state.filters.item.id) {
        this.$emit('saved', this.$store.state.filters.item.id);
      } else {
        throw new Error('Unknown error');
      }

      this.addNotification({
        color: 'success',
        message: 'Filtre sauvegardé avec succès.',
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Une erreur est survenue lors de la sauvegarde du filtre.',
      });
    }
  }

  @Watch('show')
  onShowChanged(show: boolean) {
    if (show) {
      this.filterToSave = this.$helpers.poorManClone({
        id: null,
        ...this.filter,
        instance_id: this.instanceId,
        private: !this.userHas('FILTERS_CREATE'),
      });
    } else {
      this.filterToSave = null;
    }
  }
}
