













































import { namespace } from 'vuex-class';
import Component, { mixins } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { PersistedContact } from 'qs_vuetify/src/types/models';

import ContactListItem from '@/components/ContactListItem.vue';

const global: any = namespace('global');

@Component({
  components: {
    ContactListItem,
    QsActionModal,
    QsButton,
    QsRelationField,
  },
})
export default class CampaignContactsModal extends mixins(DataRouteGuards) {
  @global.Mutation addNotification!: Function;

  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  contact: PersistedContact | null = null;

  dummyItem = {};

  queryDefinition = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      '!status': [
        'DBL',
        'DEC',
        'RET',
      ].join(','),
      fields: [
        'district',
        'email',
        'full_name',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
    },
  }

  viewParams = {
    call_campaigns: {
      fields: [
        '*',
        'contacts.email',
        'contacts.full_name',
        'contacts.home_phone',
        'contacts.status',
        'contacts.v1_contact_id',
        'users.email',
        'users.contact.full_name',
        'users.contact.home_phone',
        'users.contact.status',
        'users.contact.v1_contact_id',
      ].join(','),
    },
  };
}
