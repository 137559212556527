var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false, value: _vm.value },
      on: {
        "click:close": function($event) {
          return _vm.$emit("input", false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Filtrer les étiquettes ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [_c("v-btn", [_vm._v(" Appliquer ")])]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" filtres ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }