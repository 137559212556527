var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "base-item-card contact-card",
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "flex-grow" }, [
            _vm.item.first_name || _vm.item.last_name
              ? _c("h1", { staticClass: "text-h2" }, [
                  _vm._v(" " + _vm._s(_vm.item.first_name) + " "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.item.last_name))
                  ])
                ])
              : _vm.item.email
              ? _c("h1", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.item.email))
                ])
              : _c("h1", { staticClass: "text-h5" }, [
                  _vm._v("#" + _vm._s(_vm.item.v1_item))
                ]),
            _vm.item.first_name || _vm.item.last_name || _vm.item.email
              ? _c("p", { staticClass: "subtitle mb-0 font-weight-medium" }, [
                  _vm._v(" #" + _vm._s(_vm.item.v1_contact_id) + " ")
                ])
              : _vm._e(),
            _c("p", { staticClass: "body-2" }, [
              _vm._v(_vm._s(_vm.item.district ? _vm.item.district.name : "-"))
            ])
          ]),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "flex-shrink align-self-start mt-1" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm._f("status")(_vm.item.status, "contacts")) +
                  " "
              ),
              _c("contact-favorite-button", { attrs: { id: _vm.item.id } }),
              _c("contact-volunteer-button", { attrs: { id: _vm.item.id } })
            ],
            1
          )
        ],
        1
      ),
      _vm.showText
        ? _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "ml-5" },
                [
                  _c("v-col", { staticClass: "body-1", attrs: { cols: "4" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("gender")(_vm.item.gender)))
                    ]),
                    _c("br"),
                    _vm.item.birthdate
                      ? _c("span", [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("age")(_vm.item.birthdate)))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "body-2" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm._f("date")(_vm.item.birthdate)) +
                                ")"
                            )
                          ]),
                          _c("br")
                        ])
                      : _vm._e()
                  ]),
                  _c("v-col", { staticClass: "body-1", attrs: { cols: "4" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.address))]),
                    _c("br"),
                    _vm.item.apartment
                      ? _c("span", [
                          _c(
                            "span",
                            {
                              class: {
                                "font-italic body-2": !_vm.item.apartment
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.item.apartment))]
                          ),
                          _c("br")
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.item.city))]),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(_vm.item.postal_code))]),
                    _c("br")
                  ]),
                  _c("v-col", { staticClass: "body-1", attrs: { cols: "4" } }, [
                    _c("span", [
                      _vm.item.home_phone
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("$qs-phone")]
                              ),
                              _vm._v(
                                _vm._s(_vm._f("phone")(_vm.item.home_phone)) +
                                  " "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "body-2" }, [
                      _vm.item.email
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("$qs-email")]
                              ),
                              _vm._v(_vm._s(_vm.item.email) + " ")
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("br"),
                    !_vm.item.last_exchange_at
                      ? _c("span", { staticClass: "caption" }, [
                          _vm._v(" Jamais contacté·e ")
                        ])
                      : _vm._e(),
                    _c("br")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showAlerts
        ? _c(
            "div",
            { staticClass: "base-item-card__alerts" },
            [
              _vm.item.contribution_current_year
                ? _c("v-alert", { attrs: { dense: "", type: "success" } }, [
                    _vm._v(
                      " A donné " +
                        _vm._s(
                          _vm._f("currency")(_vm.item.contribution_current_year)
                        ) +
                        " en " +
                        _vm._s(_vm.thisYear) +
                        " "
                    )
                  ])
                : _vm.item.contribution_last_year
                ? _c("v-alert", { attrs: { dense: "", type: "warning" } }, [
                    _vm._v(
                      " A donné " +
                        _vm._s(
                          _vm._f("currency")(_vm.item.contribution_last_year)
                        ) +
                        " en " +
                        _vm._s(_vm.lastYear) +
                        " "
                    )
                  ])
                : _vm.item.status !== "CTC" && !_vm.item.last_contribution
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v(" N'a jamais fait de don ")]
                  )
                : _vm._e(),
              !_vm.item.address
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v("Adresse manquante")]
                  )
                : _vm._e(),
              !_vm.item.email
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v("Courriel manquant")]
                  )
                : _vm._e(),
              !_vm.item.home_phone
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v("Téléphone manquant")]
                  )
                : _vm._e(),
              _vm.item.last_membership && !_vm.item.current_membership
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "warning" } },
                    [
                      _vm._v(
                        " Membre expiré depuis le " +
                          _vm._s(
                            _vm._f("date")(_vm.item.last_membership.period_end)
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.item.status === "CTC"
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "warning" } },
                    [_vm._v(" Non membre ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }