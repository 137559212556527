























































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { PersistedCallCampaign, PersistedUser } from 'qs_vuetify/src/types/models';

import QsJoinCallCampaignCard
  from 'qs_vuetify/src/components/CallCampaign/QsJoinCallCampaignCard.vue';

const auth: any = namespace('auth');
const globalViews: any = namespace('globalViews');

@Component({
  components: {
    QsJoinCallCampaignCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Home extends mixins(AuthenticationMixin, DataRouteGuards) {
  @auth.Getter loading!: boolean;
  @globalViews.Getter pointageCallCampaigns!: Array<PersistedCallCampaign>;

  @globalViews.Mutation('pointageCallCampaigns')
  setPointageCallCampaigns!: (arg: Array<PersistedCallCampaign>) => unknown;

  @globalViews.Mutation('pointageCallCampaignsLoaded')
  setPointageCallCampaignsLoaded!: (arg: boolean) => unknown;

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.reloadDataRoutesData();
    } else {
      this.setPointageCallCampaigns([]);
      this.setPointageCallCampaignsLoaded(false);
    }
  }

  get unassignedPublicCampaigns(): Array<PersistedCallCampaign> {
    return this.pointageCallCampaigns.filter(
      (c: PersistedCallCampaign) => !!c.public
        && c.users && !c.users.find((u: PersistedUser) => u.id === this.user?.id),
    );
  }

  get assignedCampaigns(): Array<PersistedCallCampaign> {
    return this.pointageCallCampaigns.filter(
      (c: PersistedCallCampaign) => c.users && c.users.find(
        (u: PersistedUser) => u.id === this.user?.id,
      ),
    );
  }
}
