var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contacts", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3", cols: "12" } },
            [
              _c("qs-filters", {
                staticClass: "contacts__filters",
                attrs: {
                  theme: "labase",
                  "active-filters": _vm.params,
                  "filters-definition": { q: _vm.filtersDefinition.q },
                  "model-name": _vm.slug
                },
                on: { input: _vm.updateFilters }
              }),
              _c(
                "v-card",
                { staticClass: "sticky", attrs: { tile: "", flat: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" Filtres "),
                      _c("v-spacer"),
                      !_vm.draftFilter
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.showFilterModal = true
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.filtersLoaded
                    ? _c("qs-list-filters", {
                        staticClass: "contacts__filters",
                        attrs: {
                          "draft-filter": _vm.draftFilter,
                          filters: _vm.filtersWithRights,
                          "model-name": _vm.slug,
                          value: _vm.selectedFilterIndex
                        },
                        on: {
                          input: _vm.setSelectedFilterIndex,
                          delete: _vm.deleteFilter,
                          duplicate: _vm.duplicateFilter,
                          edit: function($event) {
                            _vm.showFilterModal = true
                          },
                          save: function($event) {
                            _vm.showSaveModal = true
                          },
                          reset: _vm.resetFilters
                        }
                      })
                    : _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "d-flex justify-space-around" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "9", cols: "12" } },
            [
              _c("qs-list", {
                attrs: {
                  error: _vm.error,
                  items: _vm.data,
                  loading: !_vm.filtersLoaded || _vm.loading,
                  "model-name": _vm.slug,
                  selection: _vm.selectedItems,
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage, select: _vm.selectAll },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("contact-card", {
                          attrs: {
                            item: item,
                            selected: _vm.isSelected(item),
                            "show-text": _vm.showExtraInformation,
                            "show-alerts": _vm.showAlerts,
                            to: { name: "ContactForm", params: { id: item.id } }
                          },
                          on: {
                            check: function($event) {
                              return _vm.updateSelection($event, item)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les informations étendues"
                                  },
                                  model: {
                                    value: _vm.showExtraInformation,
                                    callback: function($$v) {
                                      _vm.showExtraInformation = $$v
                                    },
                                    expression: "showExtraInformation"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les alertes"
                                  },
                                  model: {
                                    value: _vm.showAlerts,
                                    callback: function($$v) {
                                      _vm.showAlerts = $$v
                                    },
                                    expression: "showAlerts"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.exportCsv }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Exporter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(" (CSV) ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-file-export")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.exportXls }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Exporter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(" (XLS) ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/call_campaigns/new"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Créer une campagne d'appels "),
                                      _vm.selectedItems.length
                                        ? _c("span", [
                                            _vm._v(" à partir la sélection")
                                          ])
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-phone")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showCallCampaignContactsModal = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Ajouter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(
                                        " comme destinataires à une campagne d'appels "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-phone-plus")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showCallCampaignUsersModal = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Affecter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(
                                        " comme militant·es à une campagne d'appels "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-phone-forward")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showMailCampaignContactsModal = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Ajouter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(
                                        " comme destinataires à un envoi courriel "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-email-plus")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showTagsContactsModal = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Affecter des étiquettes "),
                                      _vm.selectedItems.length
                                        ? _c("span", [
                                            _vm._v(" à la sélection")
                                          ])
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-tag-plus")])],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("qs-filter-modal", {
        attrs: {
          repository: "Contact",
          filter: _vm.draftFilter,
          value: _vm.showFilterModal
        },
        on: {
          "update:value": function($event) {
            _vm.showFilterModal = $event
          },
          input: function($event) {
            _vm.showFilterModal = $event
          },
          apply: _vm.applyFilter,
          cancel: _vm.resetFilters
        }
      }),
      _c("add-to-call-campaign-modal", {
        attrs: {
          filters: _vm.filters,
          params: _vm.actionParams,
          value: _vm.showCallCampaignContactsModal
        },
        on: {
          "update:value": function($event) {
            _vm.showCallCampaignContactsModal = $event
          },
          input: function($event) {
            _vm.showCallCampaignContactsModal = $event
          }
        }
      }),
      _c("add-to-mail-campaign-modal", {
        attrs: {
          filters: _vm.filters,
          params: _vm.actionParams,
          value: _vm.showMailCampaignContactsModal
        },
        on: {
          "update:value": function($event) {
            _vm.showMailCampaignContactsModal = $event
          },
          input: function($event) {
            _vm.showMailCampaignContactsModal = $event
          }
        }
      }),
      _c("add-users-to-call-campaign-modal", {
        attrs: {
          filters: _vm.filters,
          params: _vm.actionParams,
          value: _vm.showCallCampaignUsersModal
        },
        on: {
          "update:value": function($event) {
            _vm.showCallCampaignUsersModal = $event
          },
          input: function($event) {
            _vm.showCallCampaignUsersModal = $event
          }
        }
      }),
      _c("add-tags-to-contacts-modal", {
        attrs: {
          filters: _vm.filters,
          params: _vm.actionParams,
          value: _vm.showTagsContactsModal
        },
        on: {
          "update:value": function($event) {
            _vm.showTagsContactsModal = $event
          },
          input: function($event) {
            _vm.showTagsContactsModal = $event
          }
        }
      }),
      _c("filter-save-modal", {
        attrs: { filter: _vm.draftFilter },
        on: { saved: _vm.filterSaved },
        model: {
          value: _vm.showSaveModal,
          callback: function($$v) {
            _vm.showSaveModal = $$v
          },
          expression: "showSaveModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }