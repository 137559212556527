





































































































import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import { PersistedContact, User } from 'qs_vuetify/src/types/models';

import BaseItemCard from '@/components/BaseItemCard.vue';
import ContactFavoriteButton from '@/components/Contact/ContactFavoriteButton.vue';
import ContactVolunteerButton from '@/components/Contact/ContactVolunteerButton.vue';

const auth: any = namespace('auth');

@Component({
  components: {
    ContactFavoriteButton,
    ContactVolunteerButton,
  },
})
export default class ContactCard extends BaseItemCard<PersistedContact> {
  @auth.Getter user!: User;

  get lastYear(): string {
    return this.$dayjs().subtract(1, 'year').format('YYYY');
  }

  get thisYear(): string {
    return this.$dayjs().format('YYYY');
  }
}
