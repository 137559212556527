













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TagChip extends Vue {
  @Prop({ required: true, type: String }) name!: string;
 @Prop({ required: false, type: Boolean, default: false }) close!: boolean;
}
