








































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import axios from 'qs_vuetify/src/plugins/axios';

import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { AppNotification } from 'qs_vuetify/src/types/components';
import { Tag } from 'qs_vuetify/src/types/models';
import { RestParams, StoreQueryDefinition } from 'qs_vuetify/src/types/states';

const global: any = namespace('global');
const tags: any = namespace('tags');

@Component({
  components: {
    QsRelationField,
  },
})
export default class AddTagsToContactsModal extends Vue {
  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @Prop(Array) filters!: { name: string; options: any }[];
  @Prop({ type: Object, required: true }) params!: RestParams;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;
  @tags.Getter('data') tags!: Array<Tag>;

  dummyItem = {};
  loading: boolean = false;
  selectedTags: Array<Tag> = [];

  queryDefinition: StoreQueryDefinition = {
    key: 'data',
    slug: 'tags',
    text: 'name',
    value: 'id',
  }

  async submit() {
    this.loading = true;

    try {
      await axios.put('/jobs/contacts/add_tags_to_contacts', {
        tags: this.selectedTags.map((t: Tag) => t.id),
      }, {
        params: {
          ...this.params,
          per_page: '*',
        },
      });

      this.addNotification({
        color: 'success',
        message: 'Les étiquettes ont été affectées aux contacts.',
      });

      this.syncedValue = false;
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Une erreur est survenue lors de l'affectation des étiquettes.",
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('syncedValue')
  onSyncedValueChanged(val: boolean) {
    if (!val) {
      this.dummyItem = {};
      this.selectedTags.splice(0, this.selectedTags.length);
    } else {
      this.$nextTick(() => {
        (this.$refs.relation as QsRelationField).focus();
      });
    }
  }
}
