import { Model } from 'qs_vuetify/src/types/models';
import ModelList from '@/views/Pointage/ModelList.vue';
import ModelDialog from '@/views/Pointage/ModelDialog.vue';
import store from '@/store/index';
import buildRestModule from 'qs_vuetify/src/plugins/rest';
import buildListViewsModule from '@/store/views/buildListViewsModule';

import i18n from 'qs_vuetify/src/plugins/i18n';
import { camelCase } from 'change-case';

export default function buildRestRoute<T extends Model>({
  path, name, moduleName: dataStoreName, params, prefix, roles, menus,
}: {
  path: string; name: string; moduleName: string; params: {}; prefix: string; menus: string[];
  roles: {
    retrieve: string;
    create: string;
    update: string;
  };
}) {
  if (!store.hasModule(dataStoreName)) {
    store.registerModule(dataStoreName, buildRestModule<T>(`${prefix}/${dataStoreName}`));
  }

  const viewStoreName = `${camelCase(dataStoreName)}Views`;

  if (!store.hasModule(viewStoreName)) {
    store.registerModule(viewStoreName, buildListViewsModule<T>());
  }

  return {
    path,
    name,
    component: ModelList,
    props: {
      dataStoreName,
      viewStoreName,
      updateRole: roles.update,
      createRole: roles.create,
    },
    meta: {
      data: {
        [dataStoreName]: {
          index: {},
        },
      },
      menus,
      title: i18n.tc(`${prefix}.${dataStoreName}.title`, 2),
      requires: roles.retrieve,
    },
    children: [
      {
        path: ':id',
        component: ModelDialog,
        props: true,
        meta: {
          data: {
            [dataStoreName]: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              index: {
                params,
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                params,
              },
            },
          },
          menus: [],
          name: `${i18n.t(`${prefix}.${dataStoreName}.new`)} ${i18n.tc(`${prefix}.${dataStoreName}.title`, 1).toLowerCase()}`,
        },
      },
    ],
  };
}
