





























import Component, { mixins } from 'vue-class-component';
import { ModelSync, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsButton from 'qs_vuetify/src/components/QsButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsEmailEditor from 'qs_vuetify/src/components/QsEmailEditor.vue';

import {
  PersistedEmail,
  User,
} from 'qs_vuetify/src/types/models';

import axios from 'qs_vuetify/src/plugins/axios';

const auth = namespace('auth');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsEmailEditor,
  },
})
export default class ContactEmailsModal extends mixins(AuthenticationMixin) {
  @auth.Getter user!: User;

  @ModelSync('value', 'change', { type: Boolean }) show!: boolean;
  @Prop({ required: true, type: Array }) items!: Array<PersistedEmail>;
  @Prop({ required: true, type: Number }) contactId!: number;

  editableItems: Array<PersistedEmail> = []

  error: string = '';

  mounted() {
    if (this.show) {
      this.editableItems = [...this.items];
    }
  }

  @Watch('show')
  onShowHide(val: boolean) {
    if (val) {
      this.editableItems = [...this.items];
    }
  }

  async save() {
    const added = this.editableItems
      .filter((email) => !this.items.find((v) => v.id === email.id));
    const removed = this.items
      .filter((email) => !this.editableItems.find((v) => v.id === email.id));

    const updated = this.editableItems.filter((email: PersistedEmail) => {
      const existing = this.items.find((v) => v.id === email.id) as PersistedEmail;

      if (existing) {
        if (existing.weight !== email.weight) {
          return true;
        }
      }

      return false;
    });

    await Promise.all(removed.map(async (email) => {
      await axios.delete(`/emails/${email.id}`);
    }));

    await Promise.all(added.map(async (email) => {
      try {
        await axios.post('/emails', { ...email, contact_id: this.contactId });
      } catch (e) {
        if (e.response?.status === 422 && e.response?.data?.errors?.email) {
          this.error = `${email.email} : ${e.response.data.errors.email[0]}`;
        }

        throw e;
      }
    }));

    await Promise.all(updated.map(async (email) => {
      await axios.put(`/emails/${email.id}`, { weight: email.weight });
    }));

    this.error = '';

    this.closeModal();
  }

  closeModal(): void {
    this.show = false;
    this.$emit('click:close');
    this.$emit('input', false);
  }
}
