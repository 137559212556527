





















import Vue from 'vue';
import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import { ModelSync, Prop } from 'vue-property-decorator';

const call_campaigns: any = namespace('call_campaigns');

@Component
export default class CallCampaignsAutocomplete extends Vue {
  @Prop({ required: false, type: String }) placeholder!: string;
  @Prop({ required: false, type: Array, default: () => [] }) excluded!: number[];

  @ModelSync('value', 'change', { type: [Number, Object] }) syncedVal!: number | null;

  @call_campaigns.Getter data!: Array<PersistedCallCampaign>;

  get items() {
    return this.data.filter((item) => !this.excluded.includes(item.id));
  }
}
