import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=1fd87062&scoped=true&"
import script from "./ContactForm.vue?vue&type=script&lang=ts&"
export * from "./ContactForm.vue?vue&type=script&lang=ts&"
import style0 from "./ContactForm.vue?vue&type=style&index=0&id=1fd87062&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd87062",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VContainer,VHover,VIcon,VProgressCircular,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_pointage_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fd87062')) {
      api.createRecord('1fd87062', component.options)
    } else {
      api.reload('1fd87062', component.options)
    }
    module.hot.accept("./ContactForm.vue?vue&type=template&id=1fd87062&scoped=true&", function () {
      api.rerender('1fd87062', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Contacts/ContactForm.vue"
export default component.exports