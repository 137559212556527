













































import Component from 'vue-class-component';

import { PersistedTag } from 'qs_vuetify/src/types/models';

import BaseItemCard from '@/components/BaseItemCard.vue';

@Component
export default class TagCard extends BaseItemCard<PersistedTag> {}
