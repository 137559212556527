var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "add-tags-to-contacts-modal" },
      on: {
        "click:outside": function($event) {
          _vm.syncedValue = false
        }
      },
      model: {
        value: _vm.syncedValue,
        callback: function($$v) {
          _vm.syncedValue = $$v
        },
        expression: "syncedValue"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Affecter des étiquettes")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c("v-divider", { staticClass: "mb-2" }),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("qs-relation-field", {
                        ref: "relation",
                        attrs: {
                          item: _vm.dummyItem,
                          label: "Étiquettes",
                          name: "add_tags_to_contacts",
                          store: _vm.queryDefinition,
                          value: _vm.selectedTags,
                          multiple: ""
                        },
                        on: {
                          input: function($event) {
                            _vm.selectedTags = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider")
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "flex-wrap" },
            [
              _c(
                "v-container",
                {
                  staticClass: "d-flex justify-center",
                  staticStyle: { width: "100%" },
                  attrs: { fluid: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        loading: _vm.loading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" Appliquer ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }