var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "contact-favorite-button ml-6",
      attrs: { icon: "", text: "" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onCatchClick($event)
        }
      }
    },
    [
      _vm.loading
        ? _c("v-icon", { attrs: { color: "accent" } }, [_vm._v("$qs-star")])
        : _vm.isFavorite
        ? _c("v-icon", { attrs: { color: "primary" } }, [_vm._v("$qs-star")])
        : _c("v-icon", { attrs: { color: "qs-light-blue" } }, [
            _vm._v("$qs-star")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }