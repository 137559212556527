var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { class: "breakpoint-" + _vm.$vuetify.breakpoint.name },
    [
      _c("qs-layout-navigation", {
        attrs: {
          "primary-menu": _vm.primaryMenuItems,
          "admin-menu": _vm.adminMenuItems,
          instances: _vm.userInstances,
          "lock-instance": _vm.lockInstance,
          theme: "labase"
        },
        on: {
          instance: _vm.setInstanceId,
          login: _vm.loadUserAndSetInitialInstance,
          logout: _vm.redirectToHome
        }
      }),
      _c("v-main", [_c("qs-layout-notifications"), _c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }