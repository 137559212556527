


































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { Tag } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const auth: any = namespace('auth');
const store: any = namespace('tags');
const view: any = namespace('tagsViews');

@Component({
  components: {
    QsActionModal,
    QsFormBuilder,
  },
})
export default class TagDialog extends mixins(
  DataRouteGuards,
  FormMixin,
  I18nMixin,
) {
  @auth.Getter instanceId!: number | null;

  @store.Action loadEmpty!: any;
  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter item!: Tag | null;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @view.Getter modal!: boolean;
  @view.Mutation setModal!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  formOrder = [
    'name',
    'description',
    'instance_id',
    'parent_id',
  ];

  viewParams = {
    tags: {
      fields: [
        '*',
        'instance.name',
        'parent.name',
        'created_by_user.contact_name',
        'updated_by_user.contact_name',
      ].join(','),
    },
  };

  mounted() {
    this.setModal(true);

    this.syncItem({
      description: '',
      instance_id: this.instanceId,
      name: '',
    });
  }

  closeModal() {
    this.setModal(false);
    this.$router.push('/tags');
  }

  async submitForm() {
    await this.submit();
    this.closeModal();
  }
}
