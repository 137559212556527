var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "call-campaign-progress qs-dark-blue--text" },
    [
      _vm.combined
        ? _c("div", { staticClass: "d-flex call-campaign-progress__bar" }, [
            _c("span", {
              staticClass: "call-campaign-progress__done",
              style: { width: _vm.done + _vm.failed + _vm.reserved + "%" }
            }),
            _c("span", {
              staticClass: "call-campaign-progress__unassigned",
              style: { width: _vm.unassigned + "%" }
            })
          ])
        : _c("div", { staticClass: "d-flex call-campaign-progress__bar" }, [
            _c("span", {
              staticClass: "call-campaign-progress__done",
              style: { width: _vm.done + "%" }
            }),
            _c("span", {
              staticClass: "call-campaign-progress__failed",
              style: { width: _vm.failed + "%" }
            }),
            _c("span", {
              staticClass: "call-campaign-progress__reserved",
              style: { width: _vm.reserved + "%" }
            }),
            _c("span", {
              staticClass: "call-campaign-progress__unassigned",
              style: { width: _vm.unassigned + "%" }
            })
          ]),
      _c(
        "div",
        {
          staticClass:
            "call-campaign-progress__information mt-3 d-flex align-baseline"
        },
        [
          _vm._t("chips"),
          _vm.combined ? _c("v-spacer") : _vm._e(),
          _vm._v(
            " Progression " + _vm._s(_vm._f("number")(_vm.progress, 2)) + " % "
          ),
          !_vm.combined ? _c("v-spacer") : _vm._e(),
          !_vm.combined
            ? _c("div", [
                _c("span", {
                  staticClass: "done",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc(
                        "call_campaigns.done_exchanges",
                        _vm.data.done_contact_exchanges
                      )
                    )
                  }
                }),
                _c("span", { staticClass: "ml-1 mr-1" }, [_vm._v("/")]),
                _c("span", {
                  staticClass: "failed",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc(
                        "call_campaigns.failed_exchanges",
                        _vm.data.failed_contact_exchanges
                      )
                    )
                  }
                }),
                _c("span", { staticClass: "ml-1 mr-1" }, [_vm._v("/")]),
                _c("span", {
                  staticClass: "reserved",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc(
                        "call_campaigns.reserved_exchanges",
                        _vm.data.reserved_contact_exchanges
                      )
                    )
                  }
                }),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v("sur "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.data.contacts))
                  ]),
                  _vm._v(" à contacter")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }