import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import { DataCollections } from 'qs_vuetify/src/mixins/DataRouteGuards';

import { MenuItem } from 'qs_vuetify/src/types/components';
import { Model } from 'qs_vuetify/src/types/models';

import CallCampaignForm from '@/views/CallCampaigns/CallCampaignForm.vue';
import CallCampaigns from '@/views/CallCampaigns/CallCampaigns.vue';
import CallCampaignExchange from '@/views/CallCampaigns/CallCampaignExchange.vue';
import ContactForm from '@/views/Contacts/ContactForm.vue';
import Contacts from '@/views/Contacts/Contacts.vue';
import NewCallCampaignDialog from '@/views/CallCampaigns/NewCallCampaignDialog.vue';
import TagDialog from '@/views/Tags/TagDialog.vue';
import Tags from '@/views/Tags/Tags.vue';
import Home from '@/views/Home.vue';
import store from '../store';
import buildRestRoute from './buildRestRoute';

declare module 'vue-router/types/router' {
  interface Route {
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }

  interface _RouteConfigBase { // eslint-disable-line @typescript-eslint/class-name-casing
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }
}

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      data: {
        globalViews: {
          pointageCallCampaigns: {
            conditional(vm: Vue) {
              return vm.$store.state.auth.user;
            },
          },
        },
      },
      icon: 'mdi-home',
      color: 'primary lighten-3',
      menus: ['primary'],
      title: 'Accueil',
    },
  },
  {
    path: '/electors',
    name: 'Electors',
    component: Contacts,
    meta: {
      data: {
        contacts: {
          filters: {
            conditional(vm: Vue) {
              return !vm.$store.state.contacts.filtersLoaded
                && vm.$store.state.auth.user;
            },
            contextual(vm: Vue) {
              return {
                accessible_from: vm.$store.state.auth.instanceId,
              };
            },
          },
        },
      },
      icon: 'mdi-account-box-multiple',
      color: 'primary lighten-3',
      menus: ['primary'],
      title: 'Électeurs',
      requires: ['POINTAGE_ELECTORS_RETRIEVE'],
    },
  },
  {
    path: '/electors/:id',
    name: 'ContactForm',
    component: ContactForm,
    props: true,
    meta: {
      data: {
        contacts: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
      },
      lockInstance: true,
      icon: 'mdi-user',
      color: 'primary lighten-3',
      menus: [],
      title: 'Contacts',
      requires: ['CONTACTS_RETRIEVE'],
    },
  },
  {
    path: '/pointage/call_campaigns',
    name: 'CallCampaigns',
    component: CallCampaigns,
    meta: {
      data: {
        pointage_call_campaigns: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.call_campaigns.lastLoadedAt;
            },
          },
        },
      },
      icon: '$qs-phone',
      color: 'primary lighten-3',
      menus: ['primary'],
      requires: ['POINTAGE_CALL_CAMPAIGNS_RETRIEVE'],
      title: 'Appels',
    },
    children: [
      {
        path: 'new',
        name: 'NewCallCampaignDialog',
        component: NewCallCampaignDialog,
        meta: {
          data: {
            electors: {
              filters: {
                conditional(vm: Vue) {
                  return !vm.$store.state.electors.filtersLoaded;
                },
              },
            },
            pointage_call_campaigns: {
              loadEmpty: {},
            },
          },
          menus: [],
          name: "Nouvelle campagne d'appels",
        },
      },
    ],
  },
  {
    path: '/pointage/call_campaigns/:id',
    name: 'CallCampaignForm',
    component: CallCampaignForm,
    props: true,
    meta: {
      data: {
        call_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              const {
                with_deleted,
                is_deleted,
                deleted_at,
              } = vm.$store.state.pointageCallCampaignsViews.params;

              return {
                id: vm.$route.params.id,
                with_deleted: (with_deleted || is_deleted || deleted_at),
              };
            },
          },
          stats: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        elector_exchanges: {
          index: {
            conditional(vm: Vue & AuthenticationMixin) {
              return vm.userHas('POINTAGE_ELECTOR_EXCHANGES_RETRIEVE');
            },
            prefix(vm: Vue) {
              return `/pointage/call_campaigns/${vm.$route.params.id}`;
            },
          },
        },
        contacts: {
          index: {
            prefix(vm: Vue) {
              return `/pointage/call_campaigns/${vm.$route.params.id}`;
            },
          },
        },
      },
      lockInstance: true,
      icon: 'mdi-phone-messsage',
      color: 'primary lighten-3',
      menus: [],
      title: "Campagne d'appels",
    },
  },
  {
    path: '/pointage/call_campaigns/:pointage_call_campaign_id/elector_exchanges/:id',
    name: 'CallCampaignExchange',
    component: CallCampaignExchange,
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      pointage_call_campaign_id: route.params.call_campaign_id,
      modelName: (store.state as any).contact_exchanges.slug,
    }),
    meta: {
      data: {
        pointage_call_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.pointage_call_campaign_id,
              };
            },
          },
          stats: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.pointage_call_campaign_id,
              };
            },
          },
        },
        contact_exchanges: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            prefix(vm: Vue) {
              return `/pointage/call_campaigns/${vm.$route.params.pointage_call_campaign_id}`;
            },
          },
        },
      },
      lockInstance: true,
      icon: 'mdi-phone-messsage',
      color: 'primary lighten-3',
      menus: [],
      title: 'Campagne d\'appel',
    },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: {
      data: {
        tags: {
          index: {},
        },
      },
      icon: 'mdi-tag',
      color: 'primary lighten-3',
      menus: ['primary'],
      requires: ['TAGS_RETRIEVE'],
      title: 'Étiquettes',
    },
    children: [
      {
        path: ':id',
        name: 'TagDialog',
        component: TagDialog,
        props: true,
        meta: {
          data: {
            tags: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
            },
          },
          menus: [],
          name: 'Nouvelle étiquette',
        },
      },
    ],
  },
  buildRestRoute({
    path: '/pointage/elections',
    name: 'Elections',
    moduleName: 'elections',
    params: {},
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_ELECTIONS_RETRIEVE',
      create: 'POINTAGE_ELECTIONS_CREATE',
      update: 'POINTAGE_ELECTIONS_UPDATE',
    },
  }),
  buildRestRoute({
    path: '/pointage/electoral_lists',
    name: 'ElectoralLists',
    moduleName: 'electoral_lists',
    params: {},
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_ELECTORAL_LISTS_RETRIEVE',
      create: 'POINTAGE_ELECTORAL_LISTS_CREATE',
      update: 'POINTAGE_ELECTORAL_LISTS_UPDATE',
    },
  }),
  buildRestRoute({
    path: '/pointage/parties',
    name: 'Parties',
    moduleName: 'parties',
    params: {
      fields: '*,district.*',
    },
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_PARTIES_RETRIEVE',
      create: 'POINTAGE_PARTIES_CREATE',
      update: 'POINTAGE_PARTIES_UPDATE',
    },
  }),
  buildRestRoute({
    path: '/pointage/polling_places',
    name: 'PollingPlaces',
    moduleName: 'polling_places',
    params: {},
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_POLLING_PLACES_RETRIEVE',
      create: 'POINTAGE_POLLING_PLACES_CREATE',
      update: 'POINTAGE_POLLING_PLACES_UPDATE',
    },
  }),
  buildRestRoute({
    path: '/pointage/polling_sectors',
    name: 'PollingSectors',
    moduleName: 'polling_sectors',
    params: {},
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_POLLING_SECTORS_RETRIEVE',
      create: 'POINTAGE_POLLING_SECTORS_CREATE',
      update: 'POINTAGE_POLLING_SECTORS_UPDATE',
    },
  }),
  buildRestRoute({
    path: '/pointage/polling_subdivisions',
    name: 'PollingSubdivisions',
    moduleName: 'polling_subdivisions',
    params: {
      fields: '*,district.*',
    },
    prefix: 'pointage',
    menus: ['primary'],
    roles: {
      retrieve: 'POINTAGE_POLLING_SUBDIVISIONS_RETRIEVE',
      create: 'POINTAGE_POLLING_SUBDIVISIONS_CREATE',
      update: 'POINTAGE_POLLING_SUBDIVISIONS_UPDATE',
    },
  }),
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('global/reset');
  next();
});

export default router;
