var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "home" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "subtitle" }, [
              _vm._v("Bienvenue sur Pointage.")
            ]),
            !_vm.loading && !_vm.userIsConnected
              ? _c("p", [
                  _vm._v(
                    " Veuillez vous connecter par le menu latéral gauche. "
                  )
                ])
              : _vm._e(),
            _vm.loading ? _c("p", [_vm._v("Connexion en cours...")]) : _vm._e(),
            _vm.routeDataLoaded
              ? _c("div", [
                  _vm.assignedCampaigns.length > 0
                    ? _c(
                        "div",
                        { staticClass: "home__assigned-campaigns mb-3" },
                        [
                          _c("h2", { staticClass: "text-h6 mb-3" }, [
                            _vm._v("Campagnes en cours")
                          ]),
                          _c(
                            "v-row",
                            _vm._l(_vm.assignedCampaigns, function(campaign) {
                              return _c(
                                "v-col",
                                {
                                  key: campaign.id,
                                  attrs: { cols: "12", md: "6" }
                                },
                                [
                                  _c("qs-join-call-campaign-card", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      assigned: "",
                                      campaign: campaign,
                                      slug: "pointage_call_campaigns"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reloadDataRoutesData([
                                          "globalViews.pointageCallCampaigns"
                                        ])
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.unassignedPublicCampaigns.length > 0
                    ? _c(
                        "div",
                        { staticClass: "home__public-campaigns mb-3" },
                        [
                          _c("h2", { staticClass: "text-h6" }, [
                            _vm._v("Campagnes publiques")
                          ]),
                          _c("div", { staticClass: "body-1 mb-3" }, [
                            _vm._v(
                              " Les campagnes publiques sont ouvertes à tous les membres de Québec solidaire. Affectez-vous à une campagne pour commencer les appels. "
                            )
                          ]),
                          _c(
                            "v-row",
                            _vm._l(_vm.unassignedPublicCampaigns, function(
                              campaign
                            ) {
                              return _c(
                                "v-col",
                                {
                                  key: campaign.id,
                                  attrs: { cols: "12", md: "6" }
                                },
                                [
                                  _c("qs-join-call-campaign-card", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      campaign: campaign,
                                      slug: "pointage_call_campaigns"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reloadDataRoutesData([
                                          "globalViews.pointageCallCampaigns"
                                        ])
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm.userIsConnected
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }