











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Filter } from 'qs_vuetify/src/types/models';

@Component
export default class FilterSelect extends Vue {
  @Prop({ type: Array, required: true }) filters!: Array<Filter>;
  @Prop({ required: true, type: Object }) value!: Filter;
}
