import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

import { RootState } from 'qs_vuetify/src/types/states';

import AuthModule from 'qs_vuetify/src/store/auth/index';
import ComponentModule from 'qs_vuetify/src/store/components/index';
import GlobalViewModule from 'qs_vuetify/src/store/views/global';
import { filters } from './filters';
import { districts } from './districts';
import { instances } from './instances';
import { pointage_call_campaigns } from './pointageCallCampaigns';
import { tags } from './tags';

import views from './views/index';

const {
  globalViews,
  pointageCallCampaignsViews,
  tagsViews,
} = views;

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'qs_pointage_v2',
  storage: window.localStorage,
  reducer(state) {
    return {
      global: state.global,
      contactsViews: state.contactsViews,
      pointageCallCampaignsViews: state.pointageCallCampaignsViews,
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    await Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      // domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        lastInstanceId: state.auth?.lastInstanceId,
        previousInstanceId: state.auth?.previousInstanceId,
        refreshToken: state.auth?.refreshToken,
        token: state.auth?.token,
        v1Token: state.auth?.v1Token,
      },
    };
  },
});

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '0.1.0',
  },
  modules: {
    auth: AuthModule,
    pointageCallCampaignsViews,
    pointage_call_campaigns,
    components: ComponentModule,
    districts,
    filters,
    global: GlobalViewModule,
    globalViews,
    instances,
    tags,
    tagsViews,
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default store;
