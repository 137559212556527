var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.syncedValue,
        "content-class": "campaign-contacts-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "v-icon",
                { staticClass: "mr-2 qs-orange--text", attrs: { large: "" } },
                [_vm._v("mdi-phone")]
              ),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("Ajouter des destinataires")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [_vm._v(" à une campagne d'appels ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: { color: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add", _vm.contact)
                    }
                  }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _c("qs-relation-field", {
          attrs: {
            description: "Sur le nom, l'id ou le numéro de membre",
            item: _vm.dummyItem,
            label: "Rechercher",
            name: "add_campaign_contact",
            query: _vm.queryDefinition,
            value: _vm.contact
          },
          on: {
            input: function($event) {
              _vm.contact = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "extra",
              fn: function(ref) {
                var item = ref.item
                return [_c("contact-list-item", { attrs: { item: item } })]
              }
            },
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.full_name) + " ")]
              }
            }
          ])
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }