

















































import Component, { mixins } from 'vue-class-component';

import QsList from 'qs_vuetify/src/components/QsList.vue';
import ModelCard from '@/components/Pointage/ModelCard.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import { Prop, Watch } from 'vue-property-decorator';
import { Model } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

import QsConfirmDialog from 'qs_vuetify/src/components/Dialog/QsConfirmDialog.vue';

@Component({
  components: {
    QsConfirmDialog,
    QsFilters,
    QsList,
    ModelCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ModelList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  LazyListMixin,
) {
    @Prop({ type: String, required: true }) dataStoreName!: string;
    @Prop({ type: String, required: true }) viewStoreName!: string;
    @Prop({ type: String, required: true }) updateRole!: string;
    @Prop({ type: String, required: true }) createRole!: string;

    constructor() {
      super();

      // small hack to prevent typescript compiler error TS2611 when overriding mixin properties (https://devblogs.microsoft.com/typescript/announcing-typescript-4-0/#properties-overriding-accessors-and-vice-versa-is-an-error)
      // eslint-disable-next-line no-underscore-dangle
      Object.defineProperties((this as any)._self, {
        setParams: {
          value: (params: RestParams) => this.$store.commit(`${this.viewStoreName}/setParams`, params),
        },
        addSelectedItem: {
          value: <T extends Model>(item: T) => this.$store.commit(`${this.viewStoreName}/addSelectedItem`, item),
        },
        removeSelectedItem: {
          value: <T extends Model>(item: T) => this.$store.commit(`${this.viewStoreName}/removeSelectedItem`, item),
        },
        setSelectedItems: {
          value: <T extends Model>(items: T[]) => this.$store.commit(`${this.viewStoreName}/setSelectedItems`, items),
        },
      });
    }

    get slug() {
      return this.dataStoreName;
    }

    get items() {
      return this.$store.getters[`${this.dataStoreName}/data`];
    }

    get selectedItems() {
      return this.$store.getters[`${this.viewStoreName}/selectedItems`];
    }

    get params(): RestParams {
      return this.$store.getters[`${this.viewStoreName}/params`];
    }

    get loading() {
      return this.$store.getters[`${this.dataStoreName}/loading`];
    }

    endDialog = false

    cardTo(item: any) {
      if (this.userIsSuperadmin
      || (item.instance_id === this.instanceId && this.userHas(this.updateRole))) {
        return this.$router.resolve(item.id.toString(), this.$route, true).location;
      }

      return null;
    }

    get hasSelection() {
      return this.selectedItems && this.selectedItems.length;
    }

    mounted() {
      this.setAction();
    }

    showDeleteDialog() {
      if (this.hasSelection) {
        this.endDialog = true;
      }
    }

    async confirmDeleteSelectedItems() {
      await Promise.all(this.selectedItems.map(async (item: any) => this.$store.dispatch(`${this.dataStoreName}/destroy`, item.id)));
      await this.reloadDataRoutesData();
    }

    @Watch('$route', { deep: true })
    @Watch('userIsConnected')
    setAction() {
      if (this.userIsSuperadmin || this.userHas(this.createRole)) {
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.$router.push(this.$router.resolve('new', this.$route, true).location); },
            color: 'primary',
            icon: 'mdi-plus',
          },
        ]);
      }
    }
}
