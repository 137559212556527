import axios from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { PointageCallCampaign } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { PointageCallCampaignsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const pointageCallCampaignsState: PointageCallCampaignsRestState = {
  ...buildRestState<PointageCallCampaign>('pointage/pointage_call_campaigns', { slug: 'pointage_call_campaigns' }),
  stats: null,
};

export const getters: GetterTree<PointageCallCampaignsRestState, RootState> = {
  ...buildRestGetters<PointageCallCampaign, PointageCallCampaignsRestState>(),
  options(state) {
    return state.options;
  },
  stats(state) {
    return state.stats;
  },
};

export const actions: ActionTree<PointageCallCampaignsRestState, RootState> = {
  ...buildRestActions<PointageCallCampaign, PointageCallCampaignsRestState>(),
  async leave(store, id) {
    const ajax = axios.delete(`/pointage/pointage_call_campaigns/${id}/users/me`);

    await ajax;
  },
  async join(store, id) {
    const ajax = axios.put(`/pointage/pointage_call_campaigns/${id}/users/me`);

    await ajax;
  },
  async stats({ commit }, { id }) {
    const ajax = axios.get(`/pointage/pointage_call_campaigns/${id}/stats`);

    const {
      data: stats,
    } = await ajax;

    commit('stats', stats);
  },
};

export const mutations: MutationTree<PointageCallCampaignsRestState> = {
  ...buildRestMutations<PointageCallCampaign, PointageCallCampaignsRestState>(),
  options(state, options) {
    state.options = options;
  },
  stats(state, stats) {
    state.stats = stats;
  },
};

export const pointage_call_campaigns: Module<PointageCallCampaignsRestState, RootState> = {
  namespaced: true,
  state: pointageCallCampaignsState,
  getters,
  actions,
  mutations,
};
