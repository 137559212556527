




























































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsFormEditor from 'qs_vuetify/src/components/QsFormEditor.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { ExportMimeType, Form } from 'qs_vuetify/src/types/components';
import {
  CallCampaignStats,
  PersistedCallCampaign,
  PersistedContact,
  PersistedContactExchange,
  PersistedFormDefinitionTemplate,
  PersistedUser,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import axios from 'qs_vuetify/src/plugins/axios';

import CallCampaignAnswers from '@/components/CallCampaign/CallCampaignAnswers.vue';
import CallCampaignProgress from '@/components/CallCampaign/CallCampaignProgress.vue';
import CampaignContactsModal from '@/components/Dialog/CampaignContactsModal.vue';
import CallCampaignUsersModal from '@/components/Dialog/CallCampaignUsersModal.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';

const callCampaigns: any = namespace('call_campaigns');
const contactExchanges: any = namespace('contact_exchanges');
const contacts: any = namespace('contacts');
const global: any = namespace('global');
const templates: any = namespace('form_definition_templates');
const view: any = namespace('callCampaignsViews');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/data', []);
    this.$store.commit('contact_exchanges/data', []);
    next();
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
  components: {
    CallCampaignAnswers,
    CallCampaignProgress,
    CallCampaignUsersModal,
    CampaignContactsModal,
    ItemNavigation,
    QsBooleanIndicator,
    QsButton,
    QsCard,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsFormEditor,
    QsHtmlEditor,
  },
})
export default class CallCampaignForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @callCampaigns.Getter data!: Array<PersistedCallCampaign>;
  @callCampaigns.Getter error!: ErrorResponse;
  @callCampaigns.Getter form!: Form;
  @callCampaigns.Getter item!: PersistedCallCampaign;
  @callCampaigns.Getter loading!: boolean;
  @callCampaigns.Getter loaded!: boolean;
  @callCampaigns.Getter slug!: string;
  @callCampaigns.Getter stats!: CallCampaignStats;
  @callCampaigns.Getter total!: number;
  @callCampaigns.Mutation('item') syncItem!: any;

  @contacts.Getter('data') contacts!: Array<PersistedContact>;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter('filtersDefinition') contactsFiltersDefinition!: FiltersDefinition | null;
  @contacts.Getter('filtersLoaded') contactsFiltersLoaded!: boolean;
  @contacts.Getter('loaded') contactsLoaded!: boolean;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;

  @contactExchanges.Getter('data') contactExchanges!: Array<PersistedContactExchange>;

  @contactExchanges.Getter('filtersDefinition')
  contactExchangesFiltersDefinition!: FiltersDefinition | null;

  @contactExchanges.Getter('filtersLoaded') contactExchangesFiltersLoaded!: boolean;
  @contactExchanges.Getter('loading') contactExchangesLoading!: boolean;
  @contactExchanges.Getter('total') contactExchangesTotal!: number;

  @templates.Getter('data') templates!: Array<PersistedFormDefinitionTemplate>;

  @view.Getter contactExchangesOptions!: any;
  @view.Getter contactExchangesParams!: RestParams;
  @view.Getter contactsOptions!: any;
  @view.Getter contactsParams!: RestParams;
  @view.Mutation setContactExchangesOptions!: any;
  @view.Mutation setContactExchangesParams!: any;
  @view.Mutation setContactsOptions!: any;
  @view.Mutation setContactsParams!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  get contactsFiltersSubset(): FiltersDefinition {
    if (!this.contactsFiltersDefinition) {
      return {};
    }

    return {
      status: this.contactsFiltersDefinition.status,
      full_name: this.contactsFiltersDefinition.full_name,
      q: this.contactsFiltersDefinition.q,
    };
  }

  contactExchangesHeaders = [
    { text: 'No. de membre', value: 'contact.v1_contact_id' },
    { text: 'Contact', value: 'contact.full_name' },
    { text: 'Appelé par', value: 'called_by_user.contact_name' },
    { text: 'Statut', value: 'status' },
    { text: 'Laissé un message', value: 'left_message' },
    { text: 'Réponses', value: 'answers' },
    { text: 'Commentaires', value: 'comments' },
    { text: 'Réservé par', value: 'reserved_by_user.contact_name' },
  ];

  contactsFiltersOpen = false;

  contactsHeaders = [
    { text: 'No. de membre', value: 'v1_contact_id' },
    { text: 'Contact', value: 'full_name' },
    { text: 'Téléphone', value: 'home_phone' },
    { text: 'Statut', value: 'status' },
    { text: 'Appels', value: 'contact_exchanges', sortable: false },
    { text: '', value: 'actions' },
  ];

  formOrder = [
    'status',
    'name',
    'description',
    'start_at',
    'end_at',
    'public',
    'leave_message',
  ];

  modalLoading = false;
  showCallCampaignContactsModal = false;
  showCallCampaignUsersModal = false;

  get viewParams() {
    return {
      call_campaigns: {
        fields: [
          '*',
          'users.email',
          'users.contact_name',
          'users.contact_id',
        ].join(','),
      },
      contact_exchanges: {
        ...ListMixin.buildListState(this.contactExchangesOptions, this.contactExchangesParams),
        fields: [
          'contact.v1_contact_id',
          'contact.full_name',
          'status',
          'left_message',
          'answers',
          'comments',
          'reserved_by_user_id',
          'reserved_by_user.contact_name',
          'called_by_user_id',
          'called_by_user.contact_name',
        ].join(','),
      },
      contacts: {
        ...ListMixin.buildListState(this.contactsOptions, this.contactsParams),
        fields: [
          'v1_contact_id',
          'full_name',
          'home_phone',
          'status',
          'contact_exchanges.status',
        ].join(','),
      },
    };
  }

  mounted() {
    this.setActions();
    this.setGlobalSubtitle();
  }

  afterSave() {
    this.setGlobalSubtitle();
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Modifications enregistrées.',
      timeout: 1000,
    });
  }

  @Watch('contactExchanges.length')
  onContactExchangesLengthChanged(length: number) {
    if (length === 0 && this.contactExchangesOptions.page !== 1) {
      this.setContactExchangesOptions({ page: 1 });
    }
  }

  @Watch('contacts.length')
  onContactsLengthChanged(length: number) {
    if (length === 0 && this.contactsOptions.page !== 1) {
      this.setContactsOptions({ page: 1 });
    }
  }

  @Watch('itemReady')
  async onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.reloadDataRoutesData(['contact_exchanges.index']);
    }
  }

  get contactExchangesExportFields(): string[] {
    return [
      'id',
      'status',
      'left_message',
      'comments',
      'contact.id',
      'contact.v1_contact_id',
      'contact.full_name',
      'called_by_user.id',
      'called_by_user.contact_id',
      'called_by_user.contact_name',
      ...Object.keys(this.item.form_definition).map((k) => `answers.${k}`),
    ];
  }

  get contactsExportFields(): string[] {
    return [
      'id',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district.name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
      'contact_exchanges.called_by_user.contact_name',
      ...Object.keys(this.item.form_definition)
        .map((k) => `contact_exchanges.answers.${k}`),
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCallCampaignUser(user: PersistedUser): Promise<void> {
    const users = [...this.item.users];
    const index = users.indexOf(user);
    users[index].loading = true;

    this.syncItem({
      ...this.item,
      users,
    });

    try {
      await axios.delete(`/call_campaigns/${this.id}/users/${user.id}`, {});
      users.splice(index, 1);

      this.syncItem({
        ...this.item,
        users,
      });

      this.addNotification({
        color: 'success',
        message: `${user.contact_name || user.email} a été retiré des militant·es.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait du·de la militant·e.',
      });
    }
  }

  goTo(location: RawLocation) {
    this.setPreviousLocation({
      name: this.$route.name || '',
      params: this.$route.params,
    });
    this.$router.push(location);
  }

  async addCallCampaignContact(contact: PersistedContact) {
    this.modalLoading = true;

    try {
      await axios.put(`/call_campaigns/${this.item.id}/contacts/${contact.id}`);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été ajouté·e aux destinataires`,
      });

      this.reloadDataRoutesData(['contacts.index', 'call_campaigns.stats']);
      this.showCallCampaignContactsModal = false;
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: `Erreur lors de l'ajout de ${contact.full_name} aux destinataires`,
      });
    } finally {
      this.modalLoading = false;
    }
  }

  async deleteCallCampaignContact(contact: PersistedContact) {
    try {
      await axios.delete(`/call_campaigns/${this.id}/contacts/${contact.id}`);

      this.reloadDataRoutesData(['contacts.index', 'call_campaigns.stats']);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été retiré des destinataires.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait du destinataire.',
      });
    }
  }

  async exportContactExchangesCsv() {
    await this.exportContactExchanges('text/csv');
  }

  async exportContactExchangesXls() {
    await this.exportContactExchanges('application/vnd.ms-excel');
  }

  async exportContactsCsv() {
    await this.exportContacts('text/csv');
  }

  async exportContactsXls() {
    await this.exportContacts('application/vnd.ms-excel');
  }

  filterExchangesByV1ContactId(id: number) {
    this.updateContactExchangesFilters('contact.v1_contact_id', id);
  }

  // eslint-disable-next-line class-methods-use-this
  groupContactExchangesByStatus(
    exchanges: Array<PersistedContactExchange>,
  ): Record<string, Array<PersistedContactExchange>> {
    return exchanges.reduce((acc, ce) => {
      if (!acc[ce.status]) {
        acc[ce.status] = [] as Array<PersistedContactExchange>;
      }

      acc[ce.status].push(ce);

      return acc;
    }, {} as Record<string, Array<PersistedContactExchange>>);
  }

  setActions() {
    const actions = [
      {
        onClick: this.submit,
        color: 'primary',
        icon: '$qs-save',
      },
    ];

    if (this.item && this.userHas('CALL_CAMPAIGNS_DELETE')) {
      if (this.item.deleted_at) {
        actions.push({
          onClick: async () => {
            await this.$store.dispatch('call_campaigns/restore', this.item.id);
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'success',
          icon: 'mdi-restore',
        });

        if (this.userIsSuperadmin) {
          actions.push({
            onClick: async () => {
              await this.confirmThenForceDeleteItem(
                "Supprimer une campagne d'appels",
                `Êtes-vous sûr-e de vouloir supprimer cette campagne d'appels?
                Cette opération ne peut pas être annulée. Toutes les données associées
                seraont perdues, y compris l'historique des appels.`,
                this.item.id,
              );
              await this.reloadDataRoutesData();
              this.setActions();
            },
            color: 'error',
            icon: 'mdi-delete-forever',
          });
        }
      } else {
        actions.push({
          onClick: async () => {
            await this.confirmThenDeleteItem(
              "Archiver une campagne d'appels",
              `Êtes-vous sûr-e de vouloir archiver cette campagne d'appels?
              Vous ne pourrez plus faire d'appels, mais les données seront conservées.`,
              this.item.id,
            );
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'error',
          icon: 'mdi-delete',
        });
      }
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  updateContactExchangesFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactExchangesParams,
    };

    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }

    newParams.page = 1;

    this.setContactExchangesParams(newParams);
  }

  updateContactsFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactsParams,
    };

    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }

    newParams.page = 1;

    this.setContactsParams(newParams);
  }

  private async exportContacts(mimeType: ExportMimeType) {
    const params = {
      ...this.contactsParams,
      prefix: `/call_campaigns/${this.item.id}`,
      fields: this.contactsExportFields.join(','),
    };

    const generationNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contacts/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.exportUrl;
      },
    });
  }

  private async exportContactExchanges(mimeType: ExportMimeType) {
    const params = {
      ...this.contactExchangesParams,
      prefix: `/call_campaigns/${this.item.id}`,
      fields: this.contactExchangesExportFields.join(','),
    };

    const generationNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contact_exchanges/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.exportUrl;
      },
    });
  }
}
