































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsConfirmDialog from 'qs_vuetify/src/components/Dialog/QsConfirmDialog.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsLoadingIndicator from 'qs_vuetify/src/components/Indicators/QsLoadingIndicator.vue';
import QsTextareaField from 'qs_vuetify/src/components/Fields/QsTextareaField.vue';
import QsThemedSelectField from 'qs_vuetify/src/components/Fields/QsThemedSelectField.vue';
import QsThemedTextareaField from 'qs_vuetify/src/components/Fields/QsThemedTextareaField.vue';
import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import QsToggleField from 'qs_vuetify/src/components/Fields/QsToggleField.vue';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import {
  CallCampaignStats,
  PersistedCallCampaign,
  PersistedContact,
  PersistedContactExchange,
  Tag,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import CallCampaignProgress from '@/components/CallCampaign/CallCampaignProgress.vue';
import ContactContributions from '@/components/Contact/ContactContributions.vue';
import ContactEmailsModal from '@/components/Dialog/ContactEmailsModal.vue';
import ContactFavoriteButton from '@/components/Contact/ContactFavoriteButton.vue';
import ContactHistory from '@/components/Contact/ContactHistory.vue';
import ContactMemberships from '@/components/Contact/ContactMemberships.vue';
import ContactSources from '@/components/Contact/ContactSources.vue';
import EditableTextField from 'qs_vuetify/src/components/EditableTextField.vue';
import SingleLineInfo from '@/components/SingleLineInfo.vue';
import TagChip from '@/components/TagChip.vue';
import TagsAutocomplete from '@/components/Tag/TagsAutocomplete.vue';
import TagsMixin from '@/mixins/TagsMixin';

const callCampaigns: any = namespace('call_campaigns');
const contactExchanges: any = namespace('contact_exchanges');
const view: any = namespace('callCampaignsViews');

@Component({
  components: {
    CallCampaignProgress,
    ContactContributions,
    ContactEmailsModal,
    ContactFavoriteButton,
    ContactHistory,
    ContactMemberships,
    ContactSources,
    EditableTextField,
    QsFormBuilder,
    QsTextareaField,
    SingleLineInfo,
    QsLoadingIndicator,
    QsToggleField,
    QsThemedSelectField,
    QsThemedTextareaField,
    QsConfirmDialog,
    TagChip,
    TagsAutocomplete,
  },
})
export default class CallCampaignExchange extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  I18nMixin,
  TagsMixin,
) {
  @callCampaigns.Getter('item') callCampaign!: PersistedCallCampaign;
  @callCampaigns.Getter stats!: CallCampaignStats;

  @contactExchanges.Getter error!: ErrorResponse | null;
  @contactExchanges.Getter item!: PersistedContactExchange;
  @contactExchanges.Getter loading!: boolean;
  @contactExchanges.Getter loaded!: boolean;
  @contactExchanges.Getter slug!: string;
  @contactExchanges.Mutation('item') syncItem!: any

  @view.Mutation addSeenContactId!: (id: number) => void;
  @view.Mutation clearSeenContactIds!: () => void;
  @view.Mutation setEmailsModal!: (val: boolean) => void;

  @view.Getter seenContactIds!: number[];
  @view.Getter emailsModal!: boolean;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  panel = false;

  viewParams = {
    call_campaigns: {
      fields: [
        'script',
        'form_definition',
        'contact_exchanges.count',
        'name',
        'instance.district.name',
        'leave_message',
      ].join(','),
    },
    contact_exchanges: {
      fields: [
        '*',
        [
          'address',
          'age',
          'apartment',
          'birthdate',
          'city',
          'created_at',
          ...[
            'answers',
            'called_at',
            'called_by_user.contact_name',
            'call_campaign.form_definition',
            'call_campaign.instance.name',
            'call_campaign.name',
            'comments',
            'left_message',
            'status',
          ].map((f) => `contact_exchanges.${f}`),
          'contributions.date',
          'contributions.transaction.amount_in_cad',
          'contributions.date',
          'contributions.transaction.type',
          'contribution_current_year',
          'contribution_previous_year',
          'district.name',
          'dpa',
          'email',
          'emails.email',
          'emails.weight',
          'first_membership.period_start',
          'first_name',
          'gender',
          'home_phone',
          'last_membership.period_end',
          'last_membership.transaction.executed_at',
          'last_name',
          'main_language',
          'postal_code',
          'revisions.*',
          'revisions.created_by_user.contact_name',
          'status',
          'tags.name',
          'transactions.amount_in_cad',
          'transactions.executed_at',
          'transactions.type',
          'updated_at',
          'v1_contact_id',
        ].map((s) => `contact.${s}`).join(','),
        'reserved_at',
        'reserved_by_user.contact_name',
      ].join(','),
    },
  };

  addTagInput = '';
  answered = true;
  endDialog = false;
  tabs: 'info' | 'tabs' = 'info';
  selectedTagId: number | null = null;

  get contact(): PersistedContact | null {
    return this.item?.contact || null;
  }

  get isContactUnsubEmail() {
    return this.contact?.tags.some((tag: Tag) => tag.id === 101);
  }

  set isContactUnsubEmail(val: boolean) {
    if (this.contact) {
      if (val) {
        this.putContactTag(this.contact.id, 101);
      } else {
        this.deleteContactTag(this.contact.id, 101);
      }
      this.reloadDataRoutesData();
    }
  }

  get isContactUnsubPhone() {
    return this.contact?.tags.some((tag: Tag) => tag.id === 102);
  }

  set isContactUnsubPhone(val: boolean) {
    if (this.contact) {
      if (val) {
        this.putContactTag(this.contact.id, 102);
      } else {
        this.deleteContactTag(this.contact.id, 102);
      }
      this.reloadDataRoutesData();
    }
  }

  onTagEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.addTagInput = '';
    }
  }

  get tags(): string[] {
    if (!this.item) {
      return [];
    }

    return this.item.tags?.map((tag: any) => tag.name) ?? [];
  }

  async patchContact(field: string) {
    if (this.contact) {
      await axios.put(`/contacts/${this.contact.id}`, { [field]: this.contact[field] });
    }
  }

  async goToNext() {
    this.$router.replace(
      `/call_campaigns/${this.callCampaign.id}/contact_exchanges/next`,
    );

    await this.reloadDataRoutesData([
      'call_campaigns.stats',
      'contact_exchanges.retrieve',
    ]);

    this.answered = true;
  }

  get status(): 'completed' | 'failed' {
    if (this.answered) {
      return 'completed';
    }

    if (this.callCampaign.leave_message && this.item.left_message) {
      return 'completed';
    }

    return 'failed';
  }

  get showEmailsModal() {
    return this.emailsModal;
  }

  set showEmailsModal(val: boolean) {
    this.setEmailsModal(val);
  }

  async completeExchange() {
    const {
      contact, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...restWithoutContact
    } = this.item;
    this.syncItem({
      ...restWithoutContact,
      status: this.status,
    });
    await this.submit();

    await this.addSeenContactId(this.contact?.id || 0);

    this.goToNext();
  }

  async quit() {
    await this.skip();

    this.$router.replace(`/call_campaigns/${this.callCampaign.id}`);
  }

  async removeContact() {
    await axios.delete(
      `/call_campaigns/${this.callCampaign.id}/contacts/${this.contact?.id || 0}`,
    );

    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Contact retiré de la campagne.',
    });

    await this.addSeenContactId(this.contact?.id || 0);

    this.goToNext();
  }

  async addSelectedTag() {
    if (this.contact && this.selectedTagId) {
      await this.putContactTag(this.contact.id, this.selectedTagId);
      this.selectedTagId = null;
      this.reloadDataRoutesData();
    }
  }

  async skip() {
    await axios.put(
      `call_campaigns/${this.callCampaign.id}/contact_exchanges/${this.item.id}/skip`,
    );

    await this.addSeenContactId(this.contact?.id || 0);
  }

  async skipAndNext() {
    await this.skip();

    this.goToNext();
  }

  async clearSeenIds() {
    await this.clearSeenContactIds();
    this.endDialog = false;
  }

  @Watch('error')
  onErrorChanged(error: ErrorResponse | null): void {
    if (!error) {
      return;
    }

    const campaignCompleteNotification = {
      color: 'success',
      message: 'La campagne est complétée! Merci pour votre aide!',
    };

    switch (error.code) {
      case 'no_matching_contact_left':
        if (this.seenContactIds.length === 0) {
          this.$router.replace('/');
          this.$store.commit('global/addNotification', campaignCompleteNotification);
        } else if (!!this.stats
          && ((this.stats.failed_contact_exchanges || 0) > 0
            || (this.stats.unassigned_contacts || 0) > 0)) {
          this.endDialog = true;
        } else {
          this.$router.replace('/');
          this.$store.commit('global/addNotification', campaignCompleteNotification);
        }
        break;
      default:
        this.$router.replace('/');
        this.$store.commit('global/addNotification', {
          color: 'error',
          message: "Une erreur s'est produite.",
        });
        throw error;
    }
  }

  @Watch('loaded')
  onLoadedChanged() {
    // eslint-disable-next-line eqeqeq
    if (this.id === 'next' || this.id != this.item.id) {
      this.$router.replace(
        `/call_campaigns/${this.callCampaign.id}/contact_exchanges/${this.item.id}`,
      );
    }
  }
}
