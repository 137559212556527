var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "contact-form qs-blue--text",
      style:
        "min-height: calc(100vh - " +
        _vm.topOffset +
        "px - " +
        _vm.bottomOffset +
        "px)",
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("item-navigation", {
                staticClass: "mt-2 mb-4",
                attrs: {
                  callback: _vm.loadNextPageAndNavigate,
                  item: _vm.item,
                  items: _vm.data,
                  "model-name": _vm.slug,
                  total: _vm.total
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "flex-grow-1 contact-form__main",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column contact-form__main__info",
              staticStyle: { "row-gap": "1px" },
              attrs: { cols: "12", lg: "9" }
            },
            [
              _vm.routeDataLoadedAndItemReady
                ? _c(
                    "v-card",
                    {
                      staticStyle: { "min-height": "249px" },
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-start"
                        },
                        [
                          _c("div", [
                            _c(
                              "h1",
                              { staticClass: "text-h1" },
                              [
                                _vm._v(" " + _vm._s(_vm.item.first_name) + " "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.item.last_name) + " "
                                    )
                                  ]
                                ),
                                _c("contact-favorite-button", {
                                  attrs: { id: _vm.item.id }
                                }),
                                _c("contact-volunteer-button", {
                                  attrs: { id: _vm.item.id }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "text-h3 mb-0 font-weight-medium"
                              },
                              [_vm._v("#" + _vm._s(_vm.item.v1_contact_id))]
                            ),
                            _c("p", { staticClass: "body-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.item.district
                                    ? _vm.item.district.name
                                    : "-"
                                )
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              staticStyle: { color: "#C1BEBC" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "#C1BEBC", small: "" }
                                },
                                [_vm._v("$qs-save")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("datetime")(_vm.item.updated_at)
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "3" } },
                                [
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "gender",
                                      placeholder: "Genre manquant",
                                      "select-items": [
                                        { text: "Femme", value: "female" },
                                        { text: "Homme", value: "male" },
                                        {
                                          text: "Non-binaire / aucun genre",
                                          value: "other"
                                        }
                                      ],
                                      type: "enum"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("gender")
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm.item.gender
                                          ? {
                                              key: "display",
                                              fn: function(ref) {
                                                var value = ref.value
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("gender")(value)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          : null
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.item.gender,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "gender", $$v)
                                      },
                                      expression: "item.gender"
                                    }
                                  }),
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "birthdate",
                                      placeholder:
                                        "Date de naissance manquante",
                                      type: "date"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("birthdate")
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm.item.birthdate
                                          ? {
                                              key: "display",
                                              fn: function(ref) {
                                                var value = ref.value
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("age")(value)
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              value
                                                            )
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          : null
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.item.birthdate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "birthdate", $$v)
                                      },
                                      expression: "item.birthdate"
                                    }
                                  }),
                                  _vm.item
                                    ? _c("self-identification", {
                                        attrs: {
                                          id: _vm.id,
                                          tags: _vm.item.tags
                                        },
                                        on: { update: _vm.reloadDataRoutesData }
                                      })
                                    : _vm._e(),
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "language",
                                      placeholder:
                                        "Pas de préférence de langue",
                                      "select-items": [
                                        { text: "Anglais", value: "en" },
                                        { text: "Français", value: "fr" }
                                      ],
                                      type: "enum"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("main_language")
                                      }
                                    },
                                    model: {
                                      value: _vm.item.main_language,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "main_language", $$v)
                                      },
                                      expression: "item.main_language"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "5" } },
                                [
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "address",
                                      placeholder: "Adresse manquante"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("address")
                                      }
                                    },
                                    model: {
                                      value: _vm.item.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "address", $$v)
                                      },
                                      expression: "item.address"
                                    }
                                  }),
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "apartment",
                                      placeholder: "Pas d'appartement"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("apartment")
                                      }
                                    },
                                    model: {
                                      value: _vm.item.apartment,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "apartment", $$v)
                                      },
                                      expression: "item.apartment"
                                    }
                                  }),
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "city",
                                      placeholder: "Municipalité manquante"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("city")
                                      }
                                    },
                                    model: {
                                      value: _vm.item.city,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "city", $$v)
                                      },
                                      expression: "item.city"
                                    }
                                  }),
                                  _c("editable-text-field", {
                                    attrs: {
                                      name: "postal_code",
                                      placeholder: "Code postal manquant"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("postal_code")
                                      }
                                    },
                                    model: {
                                      value: _vm.item.postal_code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "postal_code", $$v)
                                      },
                                      expression: "item.postal_code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "4" } },
                                [
                                  _c("editable-text-field", {
                                    attrs: {
                                      mask: "### ###-####",
                                      name: "home_phone",
                                      placeholder: "-"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.patch("home_phone")
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "qs-blue--text",
                                                  attrs: { small: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.item.home_phone
                                                          ? "$qs-phone"
                                                          : "$qs-phone-off"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4175706496
                                    ),
                                    model: {
                                      value: _vm.item.home_phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "home_phone", $$v)
                                      },
                                      expression: "item.home_phone"
                                    }
                                  }),
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var hover = ref.hover
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-2 qs-blue--text",
                                                      attrs: { small: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.mainEmail
                                                              ? "$qs-email"
                                                              : "$qs-email-off"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.setEmailsModal(
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "min-width": "100px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mainEmail
                                                                  ? _vm
                                                                      .mainEmail
                                                                      .email
                                                                  : "-"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: hover,
                                                              expression:
                                                                "hover"
                                                            }
                                                          ],
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4181953319
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "d-flex justify-center align-center",
                      staticStyle: { height: "249px" },
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    ],
                    1
                  ),
              _vm.routeDataLoadedAndItemReady
                ? _c(
                    "v-card",
                    {
                      staticStyle: { "min-height": "234px" },
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h2 text-uppercase" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2 qs-orange--text",
                              attrs: { large: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.item.status === "CTC"
                                      ? "$qs-membership-off"
                                      : "$qs-membership"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("status")(_vm.item.status, "contacts")
                              )
                            )
                          ]),
                          _c("v-spacer"),
                          _c(
                            "qs-button",
                            {
                              attrs: {
                                disabled: !_vm.routeDataLoadedAndItemReady
                              },
                              on: { click: _vm.completeMembership }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(" Compléter une adhésion ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { staticClass: "pt-4" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "4" } },
                                [
                                  _c("contact-memberships", {
                                    attrs: { item: _vm.item }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "4" } },
                                [
                                  _c("contact-contributions", {
                                    attrs: { item: _vm.item }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "body-1", attrs: { cols: "4" } },
                                [
                                  _c("contact-sources", {
                                    attrs: { item: _vm.item }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "d-flex justify-center align-center",
                      staticStyle: { height: "234px" },
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    ],
                    1
                  ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "6" } },
                    [
                      _vm.routeDataLoadedAndItemReady
                        ? _c(
                            "v-card",
                            {
                              staticClass: "flex-grow-1",
                              attrs: { flat: "", tile: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "flex-grow-1 text-h2 text-uppercase"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2 qs-pink--text",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("$qs-calendar")]
                                  ),
                                  _vm._v(" Événements "),
                                  _c("v-spacer"),
                                  _c(
                                    "qs-button",
                                    { attrs: { disabled: "" } },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-center caption" },
                                [_vm._v(" Aucun événement ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            {
                              staticClass:
                                "d-flex flex-grow-1 justify-center align-center",
                              staticStyle: { height: "100px" },
                              attrs: { flat: "", tile: "" }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "flex-grow-1 d-flex", attrs: { cols: "6" } },
                    [
                      _vm.routeDataLoadedAndItemReady
                        ? _c(
                            "v-card",
                            {
                              staticClass: "flex-grow-1",
                              staticStyle: { "margin-left": "1px" },
                              attrs: { flat: "", tile: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "flex-grow-1 text-h2 text-uppercase"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2 qs-blue--text",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("mdi-tag")]
                                  ),
                                  _vm._v(" Étiquettes "),
                                  _c("v-spacer"),
                                  _c(
                                    "qs-button",
                                    {
                                      attrs: {
                                        disabled: !_vm.routeDataLoadedAndItemReady
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setTagsModal(true)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.item && _vm.item.tags.length > 0
                                ? _c(
                                    "v-card-text",
                                    { staticClass: "caption" },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "contact-form__main__info__tags d-flex flex-column justify-start"
                                        },
                                        _vm._l(_vm.tags, function(tag) {
                                          return _c(
                                            "li",
                                            { key: tag.id },
                                            [
                                              _c("tag-chip", {
                                                attrs: {
                                                  close: !_vm.isReservedTag(
                                                    tag
                                                  ),
                                                  name: tag.name
                                                },
                                                on: {
                                                  "click:close": function(
                                                    $event
                                                  ) {
                                                    return _vm.removeTag(tag.id)
                                                  }
                                                }
                                              }),
                                              tag.children &&
                                              tag.children.length
                                                ? _c(
                                                    "ul",
                                                    _vm._l(
                                                      tag.children,
                                                      function(childTag) {
                                                        return _c(
                                                          "li",
                                                          { key: childTag.id },
                                                          [
                                                            _c("tag-chip", {
                                                              attrs: {
                                                                close: !_vm.isReservedTag(
                                                                  childTag
                                                                ),
                                                                name:
                                                                  childTag.name
                                                              },
                                                              on: {
                                                                "click:close": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeTag(
                                                                    childTag.id
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                : _c(
                                    "v-card-text",
                                    { staticClass: "text-center caption" },
                                    [_vm._v(" Aucune étiquette ")]
                                  )
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            {
                              staticClass:
                                "d-flex flex-grow-1 justify-center align-center",
                              staticStyle: { height: "100px" },
                              attrs: { flat: "", tile: "" }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-start contact-form__main__history",
              attrs: { cols: "12", lg: "3" }
            },
            [
              _vm.routeDataLoadedAndItemReady
                ? _c(
                    "contact-history",
                    { attrs: { item: _vm.item, "model-name": "contacts" } },
                    [
                      _c(
                        "qs-btn",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            color: "qs-orange",
                            disabled: !(_vm.routeDataLoaded && _vm.itemReady),
                            width: "100%",
                            icon: "mdi-plus"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setCommentsModal(true)
                            }
                          }
                        },
                        [_vm._v(" Ajouter un commentaire ")]
                      ),
                      _c(
                        "qs-btn",
                        {
                          attrs: {
                            color: "#C5E783",
                            disabled: !(_vm.routeDataLoaded && _vm.itemReady),
                            width: "100%",
                            icon: "mdi-message"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setContactExchangeModal(true)
                            }
                          }
                        },
                        [_vm._v(" Échanger ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "d-flex justify-center align-center",
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "min-height": "100px"
                      },
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.item && _vm.item.emails
        ? _c("contact-emails-modal", {
            attrs: { "contact-id": _vm.item.id, items: _vm.item.emails },
            on: { input: _vm.showUpdate },
            model: {
              value: _vm.showEmailsModal,
              callback: function($$v) {
                _vm.showEmailsModal = $$v
              },
              expression: "showEmailsModal"
            }
          })
        : _vm._e(),
      _vm.item
        ? _c("contact-comments-modal", {
            attrs: { "contact-id": _vm.item.id, "view-params": _vm.viewParams }
          })
        : _vm._e(),
      _vm.item && _vm.item.tags
        ? _c("contact-tags-modal", {
            attrs: {
              excluded: _vm.item.tags,
              id: _vm.id,
              "view-params": _vm.viewParams
            },
            on: {
              input: function($event) {
                return _vm.showUpdate("L'étiquette a été ajoutée.")
              }
            }
          })
        : _vm._e(),
      _vm.item
        ? _c("add-specific-contact-to-call-campaign-modal", {
            attrs: {
              "contact-id": _vm.item.id,
              "excluded-campaigns": _vm.item.contact_exchanges.map(function(c) {
                return c.call_campaign_id
              }),
              "view-params": _vm.viewParams
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }