import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { PointageCallCampaignsViewsState } from '@/types/states';

export const pointageCallCampaignsViews: Module<PointageCallCampaignsViewsState, RootState> = {
  namespaced: true,
  state: {
    electorExchangesOptions: {
      sortBy: ['elector.full_name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 10,
    },
    electorExchangesParams: {
      page: 1,
      per_page: 20,
    },
    electorsOptions: {
      sortBy: ['full_name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 10,
    },
    electorsParams: {
      page: 1,
      per_page: 20,
    },
    currentElectorExchangeId: -1,
    params: {
      page: 1,
      per_page: 20,
    },
    seenAddressIds: [],
    selectedItems: [],
    showExtraInformation: true,
    usersModal: false,
  },
  getters: {
    electorExchangesOptions(state) {
      return state.electorExchangesOptions;
    },
    electorExchangesParams(state) {
      return state.electorExchangesParams;
    },
    electorsOptions(state) {
      return state.electorsOptions;
    },
    electorsParams(state) {
      return state.electorsParams;
    },
    currentElectorExchangeId(state) {
      return state.currentElectorExchangeId;
    },
    params(state) {
      return state.params;
    },
    seenAddressIds(state) {
      return state.seenAddressIds;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
    usersModal(state) {
      return state.usersModal;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    addSeenAddressId(state, seenAddressId) {
      const seenAddressIds = [...state.seenAddressIds, seenAddressId];

      // no duplicates
      state.seenAddressIds = [...new Set(seenAddressIds)];
    },
    clearSeenAddressIds(state) {
      state.seenAddressIds = [];
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setElectorExchangesOptions(state, options) {
      state.electorExchangesOptions = options;
    },
    setElectorExchangesParams(state, params) {
      state.electorExchangesParams = params;
    },
    setElectorsOptions(state, options) {
      state.electorsOptions = options;
    },
    setElectorsParams(state, electorsParams) {
      state.electorsParams = electorsParams;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
    setUsersModal(state, value) {
      state.usersModal = value;
    },
  },
};

export default pointageCallCampaignsViews;
