import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { TagsViewsState } from '@/types/states';

export const tagsViews: Module<TagsViewsState, RootState> = {
  namespaced: true,
  state: {
    modal: false,
    params: {
      page: 1,
    },
    selectedItems: [],
    showExtraInformation: true,
  },
  getters: {
    modal(state) {
      return state.modal;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setModal(state, value) {
      state.modal = value;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
  },
};

export default tagsViews;
