



















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import FilterSelect from '@/components/FilterSelect.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { Contact, Filter } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const auth: any = namespace('auth');
const callCampaigns: any = namespace('call_campaigns');
const contacts: any = namespace('contacts');
const contactsView: any = namespace('contactsViews');
const view: any = namespace('callCampaignsViews');

@Component({
  components: {
    FilterSelect,
    QsFormBuilder,
    QsHtmlEditor,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewCallCampaignForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @auth.Getter instanceId!: number | null;

  @callCampaigns.Action loadEmpty!: any;
  @callCampaigns.Getter error!: ErrorResponse;
  @callCampaigns.Getter form!: Form;
  @callCampaigns.Getter item!: Contact;
  @callCampaigns.Getter loading!: boolean;
  @callCampaigns.Getter slug!: string;
  @callCampaigns.Mutation('item') syncItem!: any

  @contacts.Getter filters!: Array<Filter>;

  @contactsView.Getter draftFilter!: Filter | null;
  @contactsView.Getter selectedFilterIndex!: number;

  @view.Getter modal!: boolean;
  @view.Mutation('modal') setModal!: any;

  mounted() {
    this.setModal(true);
    if (this.itemReady) {
      this.setInitialFilter();
    }
  }

  formOrder = [
    'name',
    'description',
    'filter',
    'start_at',
    'end_at',
    'public',
    'leave_message',
  ];

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: "Campagne créée avec succès. L'ajout des contacts peut prendre quelques minutes.",
      timeout: 2000,
    });
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setInitialFilter();
    }
  }

  @Watch('routeDataLoaded')
  onDataRouteLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setInitialFilter();
    }
  }

  setInitialFilter() {
    if (this.routeDataLoaded && this.itemReady) {
      switch (this.selectedFilterIndex) {
        case 0:
          if (this.draftFilter) {
            this.syncItem({
              ...this.item,
              filter: this.draftFilter.filter,
            });
          }
          break;
        case 1:
          this.syncItem({
            ...this.item,
            filter: { '!id': 0 },
          });
          break;
        default:
          this.syncItem({
            ...this.item,
            filter: this.filters[this.selectedFilterIndex - 2].filter,
          });
      }
    }
  }

  get filtersWithDefaults(): Array<Filter> {
    const filters: Array<Filter> = [
      {
        id: null,
        name: 'Aucun filtre initial',
        filter: { id: 0 },
      },
    ];

    if (this.draftFilter) {
      filters.push({
        ...this.draftFilter,
        id: -3,
      });
    }

    filters.push({
      id: null,
      name: 'Tous les contacts',
      filter: {
        '!id': 0,
      },
    });

    filters.push(...this.filters);

    return filters;
  }

  closeModal(): void {
    this.$router.push({ name: 'CallCampaigns' });
    this.setModal(false);
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', 'Nouvelle campagne d\'appels');
    }
    this.$emit('updateHead');
  }

  submitForm() {
    this.syncItem({
      ...this.item,
      instance_id: this.instanceId,
    });
    this.submit();
  }
}
