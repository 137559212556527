






















































































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsFilterModal from 'qs_vuetify/src/components/Dialog/QsFilterModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsListFilters from 'qs_vuetify/src/components/QsListFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FiltersMixin from 'qs_vuetify/src/mixins/FiltersMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { ExportMimeType } from 'qs_vuetify/src/types/components';
import { Filter, PersistedContact } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import AddTagsToContactsModal from '@/components/Dialog/AddTagsToContactsModal.vue';
import AddToCallCampaignModal from '@/components/Dialog/AddToCallCampaignModal.vue';
import AddToMailCampaignModal from '@/components/Dialog/AddToMailCampaignModal.vue';
import AddUsersToCallCampaignModal from '@/components/Dialog/AddUsersToCallCampaignModal.vue';
import ContactCard from '@/components/Contact/ContactCard.vue';
import FilterSaveModal from '@/components/Dialog/FilterSaveModal.vue';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const global: any = namespace('global');
const view: any = namespace('contactsViews');

@Component({
  components: {
    AddTagsToContactsModal,
    AddToCallCampaignModal,
    AddToMailCampaignModal,
    AddUsersToCallCampaignModal,
    ContactCard,
    FilterSaveModal,
    QsFilterModal,
    QsFilters,
    QsList,
    QsListFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Contacts extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FiltersMixin,
  LazyListMixin,
  ListMixin,
) {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @contacts.Getter data!: Array<PersistedContact>;
  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter filtersDefinition!: FiltersDefinition | null;
  @contacts.Getter loading!: boolean;
  @contacts.Getter slug!: string;
  @contacts.Getter total!: number;

  @contacts.Getter filters!: Array<Filter>;
  @contacts.Getter filtersLoaded!: boolean;

  // 0 => Always draftFilter , 1 => Always "All contacts"
  @view.Getter selectedFilterIndex!: number;
  @view.Mutation setSelectedFilterIndex!: (index: number) => void;

  @view.Mutation addSelectedItem!: any;
  @view.Getter draftFilter!: Filter;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<PersistedContact>;
  @view.Mutation setDraftFilter!: (filter: Filter | null) => void;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  showCallCampaignContactsModal = false
  showCallCampaignUsersModal = false
  showMailCampaignContactsModal = false
  showFilterModal = false
  showTagsContactsModal = false
  showSaveModal = false;

  defaultParams = {
    per_page: 20,
    fields: [
      'address',
      'apartment',
      'birthdate',
      'city',
      'contribution_current_year',
      'contribution_last_year',
      'district.name',
      'email',
      'first_name',
      'gender',
      'home_phone',
      'last_exchange_at',
      'last_name',
      'postal_code',
      'status',
      'v1_contact_id',
    ].join(','),
  }

  viewStoreName = 'contactsViews';

  get filtersWithRights(): Array<Filter> {
    return this.filters.map((f: Filter) => ({
      ...f,
      _can_delete: this.userHas('FILTERS_DELETE') || f.created_by_user_id === this.user?.id,
    }));
  }

  get showAlerts() {
    return this.$store.state.contactsViews.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('contactsViews/showAlerts', val);
  }

  mounted() {
    this.setAction();

    this.$store.commit('contacts/exportFields', [
      'id',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district.name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
    ]);
  }

  async exportCsv() {
    await this.export('text/csv');
  }

  async exportXls() {
    await this.export('application/vnd.ms-excel');
  }

  async filterSaved(filterId: number) {
    this.setDraftFilter(null);

    this.filtersReloading = true;
    await this.$store.commit('contacts/filtersLoaded', false);
    await this.reloadDataRoutesData(['contacts.filters']);
    this.filtersReloading = false;

    const filterIndex: number = this.filters.findIndex((f) => f.id === filterId);
    this.setSelectedFilterIndex(filterIndex + 2);
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('CONTACTS_CREATE') ? [
        {
          onClick: () => { this.$router.push('contacts/new'); },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ] : [],
    );
  }

  private async export(mimeType: ExportMimeType) {
    const params = this.actionParams;

    const generationNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contacts/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.exportUrl;
      },
    });
  }
}
